import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import IBank, { IBankSummary } from '@root/types/Bank'
import bankApi, { BankSource, IFetchBankAccountsResp } from '@root/api/bankApi'
import { DDLogger } from '@root/utils'
import { useUIStore } from './uiStore'

interface LinkedBank {
  bankName: string
  last4DigitAccountNumber: string
}

interface BankAction {
  setSelectedBank: (bank: IBank | IBankSummary) => void
  getBankAccounts: () => Promise<IFetchBankAccountsResp>
  removeBank: (bankId: string, source: BankSource) => Promise<void>
  pollBankStatus: (props: {
    bank?: IBankSummary
    source: BankSource
    onSuccess: () => void
    onError?: () => void
  }) => Promise<void>
  setLinkedBank: (bank: LinkedBank) => void
  setBvLinkingRef: (bvLinkingRef: React.RefObject<HTMLDivElement>) => void
  reset: () => void
}

interface BankState {
  banks?: IBankSummary[]
  selectedBank?: IBank | IBankSummary
  linkedBank?: LinkedBank
  bvLinkingRef: React.RefObject<HTMLDivElement> | null
  hasFetchingCompleted?: boolean
}

const initialState: BankState = {
  banks: undefined,
  selectedBank: undefined,
  linkedBank: undefined,
  bvLinkingRef: null,
  hasFetchingCompleted: undefined,
}

const useBankStore = create<BankAction & BankState>()(
  persist(
    (set, getState) => ({
      ...initialState,
      reset: () => {
        set(initialState)
      },
      setSelectedBank: (bank: IBank | IBankSummary) => {
        set({ selectedBank: bank })
      },
      getBankAccounts: async () => {
        const resp = await bankApi.fetchBankAccounts()
        set({ banks: [...resp.data.banks], hasFetchingCompleted: true })
        return resp
      },
      removeBank: async (bankId: string, source: BankSource) => {
        const { setShowNotificationModal } = useUIStore.getState()
        const { banks, getBankAccounts } = getState()
        try {
          const resp = await bankApi.deleteBankAccount(bankId, source)
          if (resp.data && banks) {
            await getBankAccounts()
          }
        } catch {
          setShowNotificationModal(true, {
            text: `Unable to remove your account. Please try again.`,
            autoHideDuration: 3000,
          })
        }
      },
      pollBankStatus: async (props) => {
        const { bank, source, onSuccess, onError } = props
        const { pollBankStatus } = getState()
        const bankId = bank?.id

        try {
          const resp = await bankApi.fetchBankAccountStatus(source, bankId)
          const { banks: respBanks } = resp.data
          const findBank = respBanks.find((each) => each.id === bankId)

          if (findBank === undefined) return

          const isCompleted = findBank.status === 'COMPLETE'

          if (!isCompleted) {
            setTimeout(() => {
              pollBankStatus(props)
            }, 3000)
            return
          }

          onSuccess()
        } catch (error) {
          DDLogger.error('pollBankStatus', JSON.stringify(error))
          onError?.()
        }
      },
      setLinkedBank: (bank) => {
        set({ linkedBank: bank })
      },
      setBvLinkingRef: (bvLinkingRef) => {
        set({ bvLinkingRef })
      },
    }),
    {
      partialize: (state) => ({
        selectedBank: state.selectedBank,
        linkedBank: state.linkedBank,
      }),
      name: 'selectedBank',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useBankStore }
