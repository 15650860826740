import { useEffect } from 'react'

const generateUniqueDeviceId = async (): Promise<string> => {
  let fingerprintId = localStorage.getItem('device_id')

  if (fingerprintId) {
    return fingerprintId
  }

  const ClientJS = await import('clientjs').then((mod) => mod.ClientJS)

  const client = new ClientJS()

  fingerprintId = client
    .getCustomFingerprint(
      client.getUserAgent(),
      client.getPlugins(),
      client.getFonts(),
      String(client.isLocalStorage()),
      String(client.isSessionStorage()),
      client.getTimeZone(),
      client.getLanguage(),
      client.getSystemLanguage(),
      String(client.isCookie()),
      client.getCanvasPrint()
    )
    .toString()

  localStorage.setItem('device_id', fingerprintId)
  return fingerprintId
}

const screenWidth = typeof window !== 'undefined' ? window.screen.width : 0
const isDesktopScreen = screenWidth > 1024
const isTabletScreen = screenWidth > 480 && screenWidth < 1024

const tabCloseEventTrigger = (controller: AbortController) => {
  if (typeof window === 'undefined') {
    return
  }
  return window.addEventListener('pagehide', (event) => {
    event.preventDefault()
    controller.abort('User closed the page')
  })
}

const createAbortController = () => {
  const controller = new AbortController()
  const { signal } = controller
  return { signal, controller }
}

function windowAnalyticsObjectExists(): boolean {
  return !(typeof window === 'undefined' || !window.analytics)
}

function useCloseModalEventListener(callback: () => void): void {
  const eventListenerHandler = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      event.preventDefault()
      return callback()
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('keydown', (event) => eventListenerHandler(event))
    }
    return () => window.removeEventListener('keydown', () => null)
  })
}

function formatSocialValidationNumber(number: number): string {
  const millions = Math.floor(number / 100000) / 10
  const formattedMillions = millions.toFixed(1).replace(/\.0$/, '')
  if (number < 1000) return 'New Offer'
  if (number < 100000) return `Chosen by ${number.toLocaleString()}`
  if (number < 1000000) return `Chosen by ${Math.floor(number / 1000)}K`
  return `Chosen by ${formattedMillions}M`
}

function formatCategorySubType(product: string): string {
  product = product.replace(/_/g, ' ')
  if (product.length > 14) {
    product = product.substring(0, 14) + '...'
  }
  return product
}

function formatDate(date: Date): string {
  const formattedDate = new Date(date)
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  const month = monthNames[formattedDate.getMonth()]
  const day = formattedDate.getDate()
  let ordinal = 'th'

  // If day's unit digit is 1, 2 or 3 and not between 11-13, set ordinal to st, nd or rd respectively. Else, leave it as th.
  if (day < 4 || day > 20) {
    switch (day % 10) {
      case 1:
        ordinal = 'st'
        break
      case 2:
        ordinal = 'nd'
        break
      case 3:
        ordinal = 'rd'
        break
    }
  }

  return `${month} ${day}${ordinal}`
}

function trimOrKeepText(text: string, charCount: number): string {
  if (text.length >= charCount) {
    return `${text.substring(0, charCount)}...`
  }
  return text
}

export {
  generateUniqueDeviceId,
  isDesktopScreen,
  isTabletScreen,
  tabCloseEventTrigger,
  createAbortController,
  windowAnalyticsObjectExists,
  useCloseModalEventListener,
  formatSocialValidationNumber,
  formatDate,
  formatCategorySubType,
  trimOrKeepText,
}

export * from './SSN'
