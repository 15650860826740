import {
  ICreateUserRequest,
  ICreateUserResponse,
  IGetUserRewardsResponse,
  IGetInvestmentAccountResponse,
  AddressValidateReq,
  AddressValidateRes,
  UpdateUserReq,
  UpdateUserRes,
  ICheckEmailExistResponse,
  IGetUserProfileResponse,
  IConfirmDeliveryResponse,
  IChallengeMFAIdPayload,
  IResetPasswordPayload,
  ISendForgetPasswordEmailPayload,
  ISendForgetPasswordEmailResponse,
  IChallengeMFAIdResponse,
  IConvertExchangeTokenResp,
  ILoginUserPayload,
  ILoginUserResponse,
  InitiatePhoneNumberUpdateReq,
  InitiatePhoneNumberUpdateRes,
  GetAccountSummaryResponse,
  ExistingUserResponse,
} from '@root/types/User'
import { useBackdoorStore } from '@root/store'
import { ENV } from '@root/config'
import { https } from '@root/utils'
import { httpsClient } from '@root/utils/httpsClient'
import recaptchaApi from './recaptchaApi'

/**
 * @usage: For the Lite flow
 * @description: Create MoneyLion Lite account
 * @returns {ICreateUserResponse}: Returns the user profile
 */
const postCreateUser = async (
  request: ICreateUserRequest
): Promise<ICreateUserResponse> => {
  const { isBypassCaptchaBackdoorEnabled } = useBackdoorStore.getState()

  let additionalRequest = {}

  if (ENV.APP_ENV === 'production' || !isBypassCaptchaBackdoorEnabled) {
    const recaptchaToken = await recaptchaApi.getRecaptchaToken('register')
    additionalRequest = { recaptchaToken }
  }

  const httpsInit = await https({
    shouldAddRequestId: true,
    shouldBypassCaptcha: isBypassCaptchaBackdoorEnabled,
    shouldCheckToken: false,
    useRecaptchaHeader: true,
    errorTitle: '[userApi - postCreateUser]',
  })

  const payload = {
    ...request,
    ...additionalRequest,
  }

  const response = await httpsInit.post<ICreateUserResponse>(
    `/user/me`,
    payload
  )

  return response.data
}

const loginUser = async (
  payload: ILoginUserPayload,
  signal?: AbortSignal
): Promise<ILoginUserResponse> => {
  const { isBypassCaptchaBackdoorEnabled } = useBackdoorStore.getState()
  const body: any = { ...payload }

  if (!isBypassCaptchaBackdoorEnabled || ENV.APP_ENV === 'production') {
    const recaptchaToken = await recaptchaApi.getRecaptchaToken('LOGIN')
    body.recaptchaToken = recaptchaToken
  }

  const resp = await (
    await https({
      shouldAddRequestId: true,
      useRecaptchaHeader: true,
      shouldCheckToken: false,
      useAuthApi: true,
      shouldBypassCaptcha: isBypassCaptchaBackdoorEnabled,
      signal,
      errorTitle: '[loginUser]',
    })
  ).post('/login', body)

  return resp.data as ILoginUserResponse
}

const convertExchangeToken = async (
  tempToken: string
): Promise<IConvertExchangeTokenResp> => {
  const body = {
    tempToken,
    recaptchaToken: await recaptchaApi.getRecaptchaToken('token_exchange'),
  }

  const resp = await (
    await https({
      useAuthApi: true,
      useRecaptchaHeader: true,
      shouldAddRequestId: true,
      shouldCheckToken: false,
      errorTitle: '[convertExchangeToken]',
    })
  ).put('/exchange/convert', body)

  return resp.data
}

const checkEmailExist = async (
  email: string
): Promise<ICheckEmailExistResponse> => {
  const recaptchaToken = await recaptchaApi.getRecaptchaToken('CHECK_EMAIL')

  // This endpoint does not require authentication
  const response = await (
    await https({
      shouldCheckToken: false,
      shouldAddRequestId: true,
      useRecaptchaHeader: true,
      errorTitle: '[checkEmailExist]',
    })
  ).get(`/user/email?email=${email}&recaptchaToken=${recaptchaToken}`)

  return response.data
}

const checkEmailExistV2 = async (
  email: string
): Promise<ExistingUserResponse | null> => {
  const params = new URLSearchParams({
    email,
  })

  // This endpoint does not require authentication
  const httpsInit = await https({
    shouldCheckToken: false,
    shouldAddRequestId: true,
    useRecaptchaHeader: false,
    errorTitle: '[MPL - checkEmailExistV2]',
    expectErrorData: true,
  })

  try {
    const response = await httpsInit.get(`/user/email/mailable?${params}`)
    const result = response.data
    if (response.status === 200) {
      return result
    }
    throw new Error('Error validate user email')
  } catch (err) {
    return null
  }
}

const getUserProfile = async (
  withSsn?: boolean
): Promise<IGetUserProfileResponse> => {
  const resp = await (
    await https({ errorTitle: '[getUserProfile]' })
  ).get('/user/getProfile', {
    params: { withProfilePicturePreview: true, withSsn },
  })
  return resp.data as IGetUserProfileResponse
}

/* MFA APIs START */

// Request for MFA code
const confirmDelivery = async (
  deliveryOptionId: string,
  signal: AbortSignal
): Promise<IConfirmDeliveryResponse> => {
  const resp = await (
    await https({ useAuthApi: true, signal, errorTitle: '[confirmDelivery]' })
  ).post('/confirmDelivery', {
    deliveryOptionId,
  })
  return resp.data as IConfirmDeliveryResponse
}

const resendDelivery = async (
  mfaSessionId: string,
  signal: AbortSignal
): Promise<IConfirmDeliveryResponse> => {
  const resp = await (
    await https({ useAuthApi: true, signal, errorTitle: '[resendDelivery]' })
  ).post('/resendDelivery', {
    mfaSessionId,
  })
  return resp.data as IConfirmDeliveryResponse
}

const challengeMFAId = async (
  payload: IChallengeMFAIdPayload,
  signal: AbortSignal
): Promise<IChallengeMFAIdResponse> => {
  const resp = await (
    await https({ useAuthApi: true, signal, errorTitle: '[challengeMFAId]' })
  ).post('login/challenge', payload)
  return resp.data
}

const sendForgetPasswordEmail = async (
  payload: ISendForgetPasswordEmailPayload,
  signal: AbortSignal
): Promise<ISendForgetPasswordEmailResponse> => {
  const { isBypassCaptchaBackdoorEnabled } = useBackdoorStore.getState()
  const body: ISendForgetPasswordEmailPayload = { ...payload }

  if (!isBypassCaptchaBackdoorEnabled || ENV.APP_ENV === 'production') {
    const recaptchaToken = await recaptchaApi.getRecaptchaToken(
      'reset_password'
    )
    body.recaptchaToken = recaptchaToken
  }

  const resp = await (
    await https({
      useRecaptchaHeader: true,
      shouldBypassCaptcha: isBypassCaptchaBackdoorEnabled,
      shouldCheckToken: false,
      useNewDashboardHeader: true,
      signal,
      errorTitle: '[sendForgetPasswordEmail]',
    })
  ).post('user/forgetPassword', body)

  return resp.data
}

const resetPassword = async (
  payload: IResetPasswordPayload,
  signal: AbortSignal
): Promise<object> => {
  const { isBypassCaptchaBackdoorEnabled } = useBackdoorStore.getState()
  const body: IResetPasswordPayload = { ...payload }

  if (!isBypassCaptchaBackdoorEnabled || ENV.APP_ENV === 'production') {
    const recaptchaToken = await recaptchaApi.getRecaptchaToken(
      'reset_password'
    )
    body.recaptchaToken = recaptchaToken
  }
  const resp = await (
    await https({
      useRecaptchaHeader: true,
      shouldBypassCaptcha: isBypassCaptchaBackdoorEnabled,
      shouldCheckToken: false,
      signal,
      errorTitle: '[resetPassword]',
    })
  ).post('user/resetPassword', body)

  return resp.data
}

const getUserRewards = async (): Promise<IGetUserRewardsResponse> => {
  const rewardsResponse = await (
    await https({ errorTitle: '[getUserRewards]' })
  ).get('user/rewards')
  return rewardsResponse.data.data.data
}

const getUserInvestmentAccount =
  async (): Promise<IGetInvestmentAccountResponse> => {
    const rewardsResponse = await (
      await https({ errorTitle: '[getUserInvestmentAccount]' })
    ).get('user/investment')
    return rewardsResponse.data.data
  }
/* MFA APIs END */

const validateAddress = async (
  payload: AddressValidateReq
): Promise<AddressValidateRes> => {
  const response = await (
    await https({ errorTitle: '[validateAddress]' })
  ).post('user/address/v2/validate', payload)
  return response.data
}

const updateUser = async (payload: UpdateUserReq): Promise<UpdateUserRes> => {
  const response = await (
    await https({ expectErrorData: true, errorTitle: '[updateUser]' })
  ).put(`user/me`, payload)
  return response.data
}

/**
 * To challenge and update phone number
 */
const challengeAndUpdatePhoneNumber = async (
  body: InitiatePhoneNumberUpdateReq
): Promise<InitiatePhoneNumberUpdateRes> => {
  const response = await (
    await https({ errorTitle: '[challengeAndUpdatePhoneNumber]' })
  ).post(`user/initiatePhoneNumber/update`, body)

  return response.data
}

/**
 * To get account summary
 */
const getAccountSummary = async (): Promise<GetAccountSummaryResponse> => {
  const response = await httpsClient(`/user/getAccountSummary`, {
    method: 'GET',
    errorTitle: '[getAccountSummary]',
  })

  return response.data
}

const userApi = {
  postCreateUser,
  loginUser,
  convertExchangeToken,
  checkEmailExist,
  getUserProfile,
  confirmDelivery,
  resendDelivery,
  challengeMFAId,
  sendForgetPasswordEmail,
  resetPassword,
  getUserRewards,
  getUserInvestmentAccount,
  validateAddress,
  updateUser,
  challengeAndUpdatePhoneNumber,
  getAccountSummary,
  checkEmailExistV2,
}

export default userApi
