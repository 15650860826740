import { ILoan, ActiveLoansV2Data } from '@root/types/Loan'
import { IGetHolidayData } from '@root/types/Holiday'
import { https } from '@root/utils'

interface IFetchLatestLoan {
  data: ILoan[]
}

interface GetActiveLoansV2 {
  code: string
  message: string
  data: ActiveLoansV2Data
}

interface IGetHolidayResponse {
  code: string
  data: IGetHolidayData
  message: string
}

const getLatestLoan = async (): Promise<IFetchLatestLoan> => {
  const response = await (
    await https({ errorTitle: '[getLatestLoan]' })
  ).get('/loans/active')
  return response.data
}

const getActiveLoansV2 = async (): Promise<GetActiveLoansV2> => {
  const response = await (
    await https({ errorTitle: '[getActiveLoansV2]' })
  ).get('/loans/active/v2')
  return response.data
}

/**
 * @usage: For the CB Plus Flow
 * @description: Get all the holiday dates
 * @param {startDate}: The start date
 * @param {endDate}: The end date
 * @returns {IGetHolidayData}: Returns a list of holiday dates
 */
const getHoliday = async (
  startDate: string,
  endDate: string
): Promise<IGetHolidayResponse> => {
  const response = await (
    await https({ errorTitle: '[getHoliday]' })
  ).get(`/loans/holiday?startDate=${startDate}&endDate=${endDate}`)
  return response.data
}

const loanApi = {
  getLatestLoan,
  getHoliday,
  getActiveLoansV2,
}

export default loanApi
