import { httpsClient } from '@root/utils/httpsClient'
import { IContentGenResponseData } from '@root/types'

type Params = {
  [key: string]: string | number | boolean
}

const fetchTodayTabContent = async (
  params: Params = {}
): Promise<IContentGenResponseData> => {
  const response = await httpsClient(`/content/todayTabContentV2`, {
    params,
    method: 'GET',
    errorTitle: '[fetchTodayTabContent]',
  })
  return response.data
}

const contentGenApi = {
  fetchTodayTabContent,
}

export default contentGenApi
