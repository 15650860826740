import dayjs from 'dayjs'
import { https, httpsClient } from '@root/utils'
import {
  OnboardStateRequest,
  OnboardStateResponse,
  RetrievePiiRes,
  VerifyIDVRes,
  IVerifyIdvV5Request,
  VerifyIdvKbaRequest,
  AchievementVideoRes,
  BudgetType,
  GetBudgetData,
  PostBudgetRequest,
  PostBudgetData,
  PostEditTransactionRequest,
  PostEditTransactionData,
} from '@root/types'
import {
  InsightsSpendingData,
  InsightsTransactionData,
  TSpendingCategoryCode,
} from '@root/types/Insights'
import { CoreOnboardAddress } from '@root/types/User'

interface GetBudgetResponse {
  code: string
  data: GetBudgetData
  message: string
}

interface PostBudgetResponse {
  code: string
  data: PostBudgetData
  message: string
}

interface GetSpendingByBanksCategoricalResponse {
  code: string
  data: {
    code: string
    data: InsightsSpendingData
    description: string
  }
  message: string
}

interface GetSpendingInsightTransactionsResponse {
  code: string
  data: {
    code: string
    data: InsightsTransactionData
    description: string
  }
  message: string
}

interface PostEditTransactionResponse {
  code: string
  data: PostEditTransactionData
  message: string
}

const updateAddress = async (payload: {
  coreOnboardAddress: CoreOnboardAddress
}): Promise<RetrievePiiRes> => {
  const response = await (
    await https({ expectErrorData: true, errorTitle: '[updateAddress]' })
  ).post(`/lifecycle/update/address`, payload)
  return response.data
}

const retrievePii = async (signal?: AbortSignal): Promise<RetrievePiiRes> => {
  const response = await (
    await https({ signal, errorTitle: '[retrievePii]' })
  ).get(`/lifecycle/retrieve/pii`)
  return response.data
}

const checkOnboardState = async (
  request: OnboardStateRequest,
  signal?: AbortSignal
): Promise<OnboardStateResponse> => {
  const response = await (
    await https({ signal, errorTitle: '[checkOnboardState]' })
  ).post(`/lifecycle/onboard/state`, request)
  return response.data
}

/**
 * @usage: For the Core Flow
 * @description: To verify core idv
 * @param {request}: IVerifyIdvV5Request
 * @returns {VerifyIDVRes}: Returns the status or idv status
 */
const postVerifyIdvV5 = async (
  request: IVerifyIdvV5Request
): Promise<VerifyIDVRes> => {
  const response = await (
    await https({ errorTitle: '[postVerifyIdvV5]' })
  ).post(`/lifecycle/verify/v5/idv`, request)
  return response.data
}

/**
 * @usage: For the Core KBA Flow
 * @description: To answer the kba questions
 * @param request: VerifyIdvKbaRequest
 * @returns VerifyIDVRes: Returns the status or idv status
 */
const postVerifyIdvKbaV5 = async (
  request: VerifyIdvKbaRequest
): Promise<VerifyIDVRes> => {
  const response = await (
    await https({ errorTitle: '[postVerifyIdvKbaV5]' })
  ).post(`/lifecycle/verify/v5/idv/kba`, request)
  return response.data
}

/**
 * @description: For getting the task status on watching 5 videos (activation widget)
 * @returns {AchievementVideoRes}: Returns the task status
 */
const getAchievementVideo = async (
  signal?: AbortSignal
): Promise<AchievementVideoRes> => {
  const response = await (
    await https({ signal, errorTitle: '[getAchievementVideo]' })
  ).get(`/lifecycle/achievement/video`)
  return response.data
}

/**
 * @usage: For the Budget section in MoneyLion Dashboard
 * @description: To get the user's budget, based on the type and date
 * @param type: BudgetType
 * @param date: The date of the month. Example: "08-31-2023"
 * @returns GetBudgetData: Returns the budget details
 */
const getBudget = async (
  type: BudgetType,
  date: Date
): Promise<GetBudgetResponse> => {
  const formattedDate = dayjs(date).format('MM-DD-YYYY')

  const response = await (
    await https({ errorTitle: '[getBudget]' })
  ).get(`/lifecycle/v1/budget?type=${type}&date=${formattedDate}`)

  return response.data
}

/**
 * @usage: For the Budget section in MoneyLion Dashboard
 * @description: To submit the user's budget details
 * @param request: PostBudgetRequest
 * @returns PostBudgetData: Returns the status and message
 */
const postBudget = async (
  request: PostBudgetRequest
): Promise<PostBudgetResponse> => {
  const response = await (
    await https({ errorTitle: '[postBudget]' })
  ).post(`/lifecycle/v1/budget`, request)
  return response.data
}

/**
 * @usage: For Accounts and Budget page
 * @description: To get the user's spending grouped by categories and banks within a date range, sorted by amount spent in descending order.
 * @param {startDate}: The start date of the month in string. Example: "08-01-2023"
 * @param {endDate}: The end date of the month in string. Example: "08-31-2023"
 * @returns {InsightsSpendingData}: Returns a list of spendings grouped by categories and banks
 */
const getSpendingByBanksCategorical = async (
  startDate: string,
  endDate: string
): Promise<GetSpendingByBanksCategoricalResponse> => {
  const response = await httpsClient(
    `/lifecycle/spending/by-banks/categorical?startDate=${startDate}&endDate=${endDate}`,
    {
      errorTitle: '[getSpendingByBanksCategorical]',
      method: 'GET',
    }
  )
  return response.data
}

/**
 * @usage: For Accounts and Budget page
 * @description: To get the user's spending transaction for the category/categories within a date range.
 * @param startDate: The start date of the month. Example: "08-01-2023"
 * @param endDate: The end date of the month. Example: "08-31-2023"
 * @param category[]: A list of categories
 * @returns Transaction[]: Returns a list of spendings transaction for the category/categories within a date range.
 */
const getSpendingInsightTransactions = async (
  startDate: string,
  endDate: string,
  categories: TSpendingCategoryCode[]
): Promise<GetSpendingInsightTransactionsResponse> => {
  if (categories.length < 1) {
    throw new Error('INSIGHT_TRANSACTION_BAD_REQUEST')
  }

  const categoriesQuery = JSON.stringify(categories)

  const response = await httpsClient(
    `/lifecycle/spending/insight-transactions?startDate=${startDate}&endDate=${endDate}&categories=${categoriesQuery}`,
    {
      errorTitle: '[getSpendingInsightTransactions]',
      method: 'GET',
    }
  )

  return response.data
}

/**
 * @usage: For Budget page
 * @description: To edit the transaction data
 * @param request: PostEditTransactionRequest
 * @returns: Returns status and message
 */
const postEditTransaction = async (
  request: PostEditTransactionRequest
): Promise<PostEditTransactionResponse> => {
  const response = await httpsClient('/lifecycle/transaction', {
    errorTitle: '[postEditTransaction]',
    method: 'POST',
    body: request,
  })
  return response.data
}

const lifecycleApi = {
  checkOnboardState,
  updateAddress,
  retrievePii,
  postVerifyIdvV5,
  postVerifyIdvKbaV5,
  getAchievementVideo,
  getBudget,
  postBudget,
  getSpendingByBanksCategorical,
  getSpendingInsightTransactions,
  postEditTransaction,
}

export default lifecycleApi
