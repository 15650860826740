import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { loansApi, subscriptionApi } from '@root/api'
import { ILoan } from '@root/types/Loan'
import { SPLIT_FEATURE_FLAG } from '@root/constants'
import { useSplitStore, useBackdoorStore, useBankStore } from '@root/store'

interface DisbursementInfo {
  disbursementAmt: number
  escrowDisbursementAmt: number
  apr: number
  totalAmount: number
}
interface CBPInitialState {
  cbpLoan?: ILoan[] | null
  disbursementInfo: DisbursementInfo
}

export interface CBPStore extends CBPInitialState {
  getCBPLoan: () => Promise<ILoan[] | undefined>
  checkCBPlusBvUnderwriting: (signal?: AbortSignal) => Promise<boolean>
  reset: () => void
}

const initialState: CBPInitialState = {
  cbpLoan: undefined,
  disbursementInfo: {
    apr: 0,
    disbursementAmt: 0,
    escrowDisbursementAmt: 0,
    totalAmount: 0,
  },
}
const useCBPStore = create<CBPStore>()(
  persist(
    (set) => ({
      ...initialState,
      getCBPLoan: async () => {
        const shouldUseActiveLoansV2 =
          useSplitStore
            .getState()
            .getSplitTreatmentIfReady(
              SPLIT_FEATURE_FLAG.ONBOARDING.cbRedirectToMobile
            )?.treatment === 'on'

        if (shouldUseActiveLoansV2) {
          const { data } = await loansApi.getActiveLoansV2()
          if (data.activeLoans.length > 0) {
            set({ cbpLoan: data.activeLoans })
          } else {
            set({ cbpLoan: null })
          }

          return data.activeLoans
        }

        const loan = await loansApi.getLatestLoan()
        if (loan.data.length > 0) {
          set({ cbpLoan: loan.data })
        } else {
          set({ cbpLoan: null })
        }

        return loan.data
      },
      checkCBPlusBvUnderwriting: async (signal) => {
        const { isCbplusBvLinkingBackdoorEnabled, cbplusBvLinkingTestValues } =
          useBackdoorStore.getState()

        const { setLinkedBank } = useBankStore.getState()

        const { data } = await subscriptionApi.postCBPlusBvUnderwriting(
          isCbplusBvLinkingBackdoorEnabled
            ? cbplusBvLinkingTestValues
            : undefined,
          signal
        )

        const {
          status,
          disbursementAmt,
          escrowDisbursementAmt,
          apr,
          bankName,
          last4DigitAccountNumber,
        } = data

        if (status === 'CBP_SUCCESS') {
          set({
            disbursementInfo: {
              disbursementAmt,
              escrowDisbursementAmt,
              apr,
              totalAmount: disbursementAmt + escrowDisbursementAmt,
            },
          })
          setLinkedBank({
            bankName,
            last4DigitAccountNumber,
          })
        }

        return status === 'CBP_SUCCESS'
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        disbursementInfo: state.disbursementInfo,
      }),
      name: 'cbPlusStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useCBPStore }
