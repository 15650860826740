/**
 * Test Environment Variables is the same as staging
 */
import rcDefaultsStaging from '../constants/remote_config_defaults_stag.json'
import rcDefaultsProduction from '../constants/remote_config_defaults_prod.json'

const APP_ENV = (process.env.NEXT_PUBLIC_APP_ENV || process.env.NODE_ENV) as
  | 'production'
  | 'development'
  | 'test'

const APP_VERSION = process.env.NEXT_PUBLIC_APP_VERSION as string

const API_URL = {
  production: 'https://dashboard-api.moneylion.com',
  development: 'https://staging-dashboard-api.moneylion.io',
  test: 'https://staging-dashboard-api.moneylion.io',
}

const AKOYA_BV_LINK = {
  production: {
    baseURL: 'https://idp.ddp.akoya.com',
    clientId: 'ycl4kj3tu2drgndis3gqkn5y6', // Get production clientId from Billy
    redirectURI: 'https://web.moneylion.com/bv-callback/akoya',
    responseType: 'code',
    scope: 'openid%20email%20profile%20offline_access',
  },
  development: {
    baseURL: 'https://sandbox-idp.ddp.akoya.com',
    clientId: 'rkpxr55t63otdyu2jjzghawsl',
    redirectURI: 'https://web.moneylion.dev/bv-callback/akoya',
    responseType: 'code',
    scope: 'openid email profile',
  },
  test: {
    baseURL: 'https://sandbox-idp.ddp.akoya.com',
    clientId: 'rkpxr55t63otdyu2jjzghawsl',
    redirectURI: 'https://web.moneylion.dev/bv-callback/akoya',
    responseType: 'code',
    scope: 'openid email profile',
  },
}

const AUTH_API_URL = {
  production: 'https://dash-auth.moneylion.com',
  development: 'https://staging-dash-auth.moneylion.io',
  test: 'https://staging-dash-auth.moneylion.io',
}

const RECAPTCHA_KEY = {
  production: process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string,
  development: process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string,
  test: process.env.NEXT_PUBLIC_RECAPTCHA_KEY as string,
}

const SEGMENT_KEY = {
  production: process.env.NEXT_PUBLIC_SEGMENT_KEY as string,
  development: process.env.NEXT_PUBLIC_SEGMENT_KEY as string,
  test: process.env.NEXT_PUBLIC_SEGMENT_KEY as string,
}

const SESSION = {
  timeout: 1000 * 60 * 9, // 9 Minutes
  countdown: 60, // 60 Seconds
}

const MONEYLION_HOMEPAGE_URL = {
  production: 'https://moneylion.com',
  development: 'https://www.moneylion.dev',
  test: 'https://www.moneylion.dev',
}
const MONEYLION_WEBAPP_URL = {
  production: 'https://web.moneylion.com',
  development: 'https://web.moneylion.dev',
  test: 'https://web.moneylion.dev',
}
const MONEYLION_MARKETPLACE_URL = {
  production: 'https://marketplace-web.moneylion.com',
  development: 'https://marketplace.moneylion.dev',
  test: 'https://marketplace.moneylion.dev',
}

const APPS_FLYER_PBA_KEY = {
  production: process.env.NEXT_PUBLIC_APPS_FLYER_PBA_KEY as string,
  development: process.env.NEXT_PUBLIC_APPS_FLYER_PBA_KEY as string,
  test: process.env.NEXT_PUBLIC_APPS_FLYER_PBA_KEY as string,
}
const APPS_FLYER_WEB_KEY = {
  production: process.env.NEXT_PUBLIC_APPS_FLYER_WEB_KEY as string,
  development: process.env.NEXT_PUBLIC_APPS_FLYER_WEB_KEY as string,
  test: process.env.NEXT_PUBLIC_APPS_FLYER_WEB_KEY as string,
}

const LOAN_WIDGET_SCRIPT_URL = {
  production: 'https://prospects-widgets.moneylion.com/v2/personal_loan.js',
  development: 'https://widgets.moneylion.dev/personal_loan.js',
  test: 'https://widgets.moneylion.dev/personal_loan.js',
}

const CREDIT_CARD_WIDGET_SCRIPT_URL = {
  production: 'https://prospects-widgets.moneylion.com/credit_card.js',
  development: 'https://widgets.moneylion.dev/credit_card.js',
  test: 'https://widgets.moneylion.dev/credit_card.js',
}

const LA_WIDGET_SCRIPT_URL = {
  production: 'https://prospects-widgets.moneylion.com/loan_approvability.js',
  development: 'https://widgets.moneylion.dev/loan_approvability.js',
  test: 'https://widgets.moneylion.dev/loan_approvability.js',
}

const EWA_WIDGET_SCRIPT_URL = {
  production: 'https://prospects-widgets.moneylion.com/ewa_carousel.js',
  development: 'https://widgets.moneylion.dev/ewa_carousel.js',
  test: 'https://widgets.moneylion.dev/ewa_carousel.js',
}

const CB_WIDGET_SCRIPT_URL = {
  production: 'https://prospects-widgets.moneylion.com/cb_carousel.js',
  development: 'https://widgets.moneylion.dev/cb_carousel.js',
  test: 'https://widgets.moneylion.dev/cb_carousel.js',
}

const PREVIEW_LOANS_WIDGET_URL = {
  production:
    'https://prospects-widgets.moneylion.com/preview_loans_carousel.js',
  development: 'https://widgets.moneylion.dev/preview_loans_carousel.js',
  test: 'https://widgets.moneylion.dev/preview_loans_carousel.js',
}

const INSURANCE_WIDGET_URL = {
  production: 'https://prospects-widgets.moneylion.com/auto_insurance.js',
  development: 'https://widgets.moneylion.dev/auto_insurance.js',
  test: 'https://widgets.moneylion.dev/auto_insurance.js',
}

const CNF_LOANS_CALCULATOR: Record<string, string> = {
  production:
    'https://moneylion.com/network/moneylion/calculators-for-marketplace-web/web-component/loans-calculator/index.js',
  development:
    'https://moneylion.com/network/moneylion/calculators-for-marketplace-web/web-component/loans-calculator/index.js',
}

const CNF_SAVINGS_CALCULATOR: Record<string, string> = {
  production:
    'https://moneylion.com/network/moneylion/calculators-for-marketplace-web/web-component/savings-calculator/index.js',
  development:
    'https://moneylion.com/network/moneylion/calculators-for-marketplace-web/web-component/savings-calculator/index.js',
}

const DATADOG = {
  appId: '176722cd-2fe0-45c1-8bad-e542014640b4',
  clientToken: 'pubf0581e3a97e8a0b44ac62464bb6fff78',
  env:
    APP_ENV === 'development' || APP_ENV === 'test' ? 'staging' : 'production',
  site: 'datadoghq.com',
  service: 'web-dashboard',
}

const SPLIT_IO_KEY = {
  production: 'k797qnsvobkb55gu0724rpqjmpprpqf56ap8',
  development: '2qrfrdta1h3er2nm00kt7qf6b65kfh0kkuho',
  test: '2qrfrdta1h3er2nm00kt7qf6b65kfh0kkuho',
}

const FINGERPRINT_KEY = 'Dr6EhuOG4SdAK3SyNDFl'
const ONE_LINKS_URL = 'https://moneylion.onelink.me/3v37/'

const ONE_LINKS = {
  default: `${ONE_LINKS_URL}49eef5b6`,
  roarmoney: `${ONE_LINKS_URL}lswlpjdx`,
  instacash: `${ONE_LINKS_URL}ff40a050`,
  cbplus: `${ONE_LINKS_URL}c52610a2`,
  investment: `${ONE_LINKS_URL}d42531d4`,
  crypto: `${ONE_LINKS_URL}28dc8ea6`,
  driver: `${ONE_LINKS_URL}vyvqchu7`,
  roundups: `${ONE_LINKS_URL}ic1ybeyd`,
}
const ONE_LINKS_GENERIC = {
  instacash: `${ONE_LINKS_URL}m1dcpm6j`,
  roarmoney: `${ONE_LINKS_URL}vpfu9b96`,
  cbplus: `${ONE_LINKS_URL}azdi303l`,
  investment: `${ONE_LINKS_URL}shhqgof6`,
  crypto: `${ONE_LINKS_URL}dhfu98dt`,
  rewards: `${ONE_LINKS_URL}4xqd48o6`,
  activeInvestment: `${ONE_LINKS_URL}wdoernze`,
}
const ONE_LINKS_ONBOARDING = {
  generic: {
    default: `${ONE_LINKS_URL}o79ol5ih`,
    cbplus: `${ONE_LINKS_URL}c52610a2`,
    crypto: `${ONE_LINKS_URL}28dc8ea6`,
    instacash: `${ONE_LINKS_URL}agxdyb2s?variant=1`,
    instacashRoarmoney: `${ONE_LINKS_URL}s16ysmhx?variant=5`,
    instacashEngineLanding: `${ONE_LINKS_URL}y1sdrlex?variant=1`,
    investment: `${ONE_LINKS_URL}d42531d4`,
    roarmoney: `${ONE_LINKS_URL}lswlpjdx`,
    roarmoneyDirectDeposit: `${ONE_LINKS_URL}b2vywtgl`,
  },
  webToAppModal: {
    default: `${ONE_LINKS_URL}67f0cnsm`,
    cbplus: `${ONE_LINKS_URL}9jgufl8v`,
    crypto: `${ONE_LINKS_URL}ifedr9bk`,
    instacash: `${ONE_LINKS_URL}vfplcnmh?variant=1`,
    investment: `${ONE_LINKS_URL}f2qdfgpv`,
    roarmoney: `${ONE_LINKS_URL}wrw5ktxf`,
    d5MissionReward: `${ONE_LINKS_URL}5421tqu6`,
  },
}
const ONE_LINK_SEARCH = {
  default: `${ONE_LINKS_URL}y8iilub8`,
}

const ONE_LINKS_BANNER = {
  roarmoney: `${ONE_LINKS_URL}bgyrg8m4`,
  instacash: `${ONE_LINKS_URL}4vva51r2`,
}

const DOWNLOAD_APP_ONE_LINKS = {
  genericLink: `${ONE_LINKS_URL}0x3bktno`,
  wowBadge: `${ONE_LINKS_URL}l9skcwjt`,
  achievements: `${ONE_LINKS_URL}m0vq0rmh`,
  activeInsightCard: `${ONE_LINKS_URL}q86o6a`,
  creditScore: `${ONE_LINKS_URL}twpi93k8`,
  debtInsight: `${ONE_LINKS_URL}3kzjxgd4`,
  investNow: `${ONE_LINKS_URL}lflin8aq`,
  defaultInsightCard: `${ONE_LINKS_URL}z7q86o6a`,
}

const EDIT_PROFILE_ONE_LINK = {
  editProfile: `${ONE_LINKS_URL}1s2jbq55`,
}

const MONEYLION_SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/moneylion',
  twitter: 'https://twitter.com/moneylion',
  linkedin: 'https://www.linkedin.com/company/moneylion',
}
const MONEYLION_REFERRAL_URL = {
  production: 'https://mlion.us',
  development: 'https://staging-www.moneylion.com/u',
  test: 'https://staging-www.moneylion.com/u',
}

const MINIMUM_FETCH_INTERVAL = {
  production: 1200000,
  development: 60000,
  test: 60000,
}

const FIREBASE_CONFIG = {
  development: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY as string,
    authDomain: 'web-app-staging-4aa34.firebaseapp.com',
    projectId: 'web-app-staging-4aa34',
    storageBucket: 'web-app-staging-4aa34.appspot.com',
    messagingSenderId: '453005526016',
    appId: '1:453005526016:web:9869087824d52dc4cd04e5',
    measurementId: 'G-LGD60F6SC3',
  },
  test: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY as string,
    authDomain: 'web-app-staging-4aa34.firebaseapp.com',
    projectId: 'web-app-staging-4aa34',
    storageBucket: 'web-app-staging-4aa34.appspot.com',
    messagingSenderId: '453005526016',
    appId: '1:453005526016:web:9869087824d52dc4cd04e5',
    measurementId: 'G-LGD60F6SC3',
  },
  production: {
    apiKey: process.env.NEXT_PUBLIC_FIREBASE_APIKEY as string,
    authDomain: 'web-app-production-cdfb9.firebaseapp.com',
    projectId: 'web-app-production-cdfb9',
    storageBucket: 'web-app-production-cdfb9.appspot.com',
    messagingSenderId: '123602647470',
    appId: '1:123602647470:web:2a7fb54915241a932d703f',
    measurementId: 'G-DH096ZE8GZ',
  },
}
const COMMON_AUTH_COOKIE_DOMAIN = {
  production: 'moneylion.com',
  development: 'moneylion.dev',
  test: 'moneylion.dev',
}
const COMMON_AUTH_DOMAIN = {
  production: 'https://accounts.moneylion.com',
  development: 'https://accounts.moneylion.dev',
  test: 'https://accounts.moneylion.dev',
}

const COMMON_AUTH_CLIENT_ID = {
  production: process.env.NEXT_PUBLIC_COMMON_AUTH_CLIENT_ID as string,
  development: process.env.NEXT_PUBLIC_COMMON_AUTH_CLIENT_ID as string,
  test: process.env.NEXT_PUBLIC_COMMON_AUTH_CLIENT_ID as string,
}

const COMMON_AUTH_CLIENT_SECRET = {
  production: process.env.NEXT_PUBLIC_COMMON_AUTH_CLIENT_SECRET as string,
  development: process.env.NEXT_PUBLIC_COMMON_AUTH_CLIENT_SECRET as string,
  test: process.env.NEXT_PUBLIC_COMMON_AUTH_CLIENT_SECRET as string,
}

const COMMON_AUTH_SCOPE = {
  production: 'profile',
  development: 'profile',
  test: 'profile',
}

const COMMON_AUTH_SIGN_UP_TEMPLATE_ID = {
  production: {
    marketing_instacash: '89e6c3fa-5a2f-4cc6-9e7a-c9f9911a7c7f',
    cbplus: 'eac6ff34-0037-4df3-9010-4f54fa672572',
    roarmoney: '6b99e42a-7edf-43b7-adf2-3b3ca9e3b3d0',
    crypto: '34bc8e0d-8d54-4a5f-bfb8-2198b5409424',
    investment: '68951e25-dc2c-4461-a94f-6ec4663045c7',
    wow: '7e13cbb6-9041-4fc8-9426-c83eb48b9d57',
    instacash: '5fe114c8-5cf1-4688-aeef-39378c16214e',
    marketplace_guest: '5cfd16d6-c550-4291-aba1-ff8e1126626f',
    marketplace_engine: 'd44a6b44-bf79-451a-af65-d7baa9070079',
  },
  development: {
    marketing_instacash: '1',
    cbplus: '2',
    roarmoney: '3',
    crypto: '4',
    investment: '5',
    wow: '6',
    instacash: '7',
    marketplace_guest: '8',
    marketplace_engine: '9',
  },
  test: {
    marketing_instacash: '1',
    cbplus: '2',
    roarmoney: '3',
    crypto: '4',
    investment: '5',
    wow: '6',
    instacash: '7',
    marketplace_guest: '8',
    marketplace_engine: '9',
  },
}

const FIREBASE_DEFAULT_CONFFIG = {
  development: rcDefaultsStaging,
  test: rcDefaultsStaging,
  production: rcDefaultsProduction,
}

const SPLIT_IO_KEY_ONBOARDING = {
  production: process.env.NEXT_PUBLIC_SPLIT_KEY_OB as string,
  development: process.env.NEXT_PUBLIC_SPLIT_KEY_OB as string,
  test: process.env.NEXT_PUBLIC_SPLIT_KEY_OB as string,
}

const PRIVACY_POLICY_URL = {
  production: 'https://www.moneylion.com/privacy-notice/',
  development: 'https://www.moneylion.dev/privacy-notice/',
  test: 'https://www.moneylion.dev/privacy-notice/',
}

const COMMUNITY_GUIDELINES_URL = {
  production: 'https://www.moneylion.com/community-guidelines/',
  development: 'https://www.moneylion.dev/community-guidelines/',
  test: 'https://www.moneylion.dev/community-guidelines/',
}

const COLUMN_TAX_CDN = {
  production: 'https://app.columnapi.com/column-tax.js',
  development: 'https://app-sandbox.columnapi.com/column-tax.js',
  test: 'https://app-sandbox.columnapi.com/column-tax.js',
}

const CONTENTFUL_SPACE_ID = {
  production: process.env.NEXT_SERVER_CONTENTFUL_SPACE_ID as string,
  development: process.env.NEXT_SERVER_CONTENTFUL_SPACE_ID as string,
  test: process.env.NEXT_SERVER_CONTENTFUL_SPACE_ID as string,
}

const CONTENTFUL_ACCESS_TOKEN = {
  production: process.env.NEXT_SERVER_CONTENTFUL_ACCESS_TOKEN as string,
  development: process.env.NEXT_SERVER_CONTENTFUL_ACCESS_TOKEN as string,
  test: process.env.NEXT_SERVER_CONTENTFUL_ACCESS_TOKEN as string,
}

const MONEYLION_HELP_LINK = 'https://help.moneylion.com'
const COMMUNICATION_PREFERENCES_URL =
  'https://preferences.moneylion.com/moneylion/preferences'
const MONEYLION_WEB_CDN = 'https://cdn-web.moneylion.com'
const MONEYLION_DOWNLOAD_APP_ROARMONEY = 'https://app.moneylion.com/roarmoney'
const CACHE_CTF_DOWNLOAD_APP_ASSETS =
  process.env.NEXT_SERVER_CACHE_CTF_DOWNLOAD_APP_ASSETS
const NEXT_BUILD_ID = process.env.NEXT_BUILD_ID

const ENV = {
  APP_ENV,
  APP_VERSION,
  API_URL: API_URL[APP_ENV],
  AUTH_API_URL: AUTH_API_URL[APP_ENV],
  AKOYA_BV_LINK: AKOYA_BV_LINK[APP_ENV],
  COMMON_AUTH_DOMAIN: COMMON_AUTH_DOMAIN[APP_ENV],
  COMMON_AUTH_CLIENT_ID: COMMON_AUTH_CLIENT_ID[APP_ENV],
  COMMON_AUTH_CLIENT_SECRET: COMMON_AUTH_CLIENT_SECRET[APP_ENV],
  COMMON_AUTH_SCOPE: COMMON_AUTH_SCOPE[APP_ENV],
  COMMON_AUTH_COOKIE_DOMAIN: COMMON_AUTH_COOKIE_DOMAIN[APP_ENV],
  COMMON_AUTH_SIGN_UP_TEMPLATE_ID: COMMON_AUTH_SIGN_UP_TEMPLATE_ID[APP_ENV],
  RECAPTCHA_KEY: RECAPTCHA_KEY[APP_ENV],
  SEGMENT_KEY: SEGMENT_KEY[APP_ENV],
  SESSION,
  MONEYLION_HOMEPAGE_URL: MONEYLION_HOMEPAGE_URL[APP_ENV],
  MONEYLION_WEBAPP_URL: MONEYLION_WEBAPP_URL[APP_ENV],
  MONEYLION_MARKETPLACE_URL: MONEYLION_MARKETPLACE_URL[APP_ENV],
  APPS_FLYER_PBA_KEY: APPS_FLYER_PBA_KEY[APP_ENV],
  APPS_FLYER_WEB_KEY: APPS_FLYER_WEB_KEY[APP_ENV],
  LOAN_WIDGET_SCRIPT_URL: LOAN_WIDGET_SCRIPT_URL[APP_ENV],
  CREDIT_CARD_WIDGET_SCRIPT_URL: CREDIT_CARD_WIDGET_SCRIPT_URL[APP_ENV],
  LA_WIDGET_SCRIPT_URL: LA_WIDGET_SCRIPT_URL[APP_ENV],
  EWA_WIDGET_SCRIPT_URL: EWA_WIDGET_SCRIPT_URL[APP_ENV],
  CB_WIDGET_SCRIPT_URL: CB_WIDGET_SCRIPT_URL[APP_ENV],
  PREVIEW_LOANS_WIDGET_SCRIPT_URL: PREVIEW_LOANS_WIDGET_URL[APP_ENV],
  INSURANCE_WIDGET_SCRIPT_URL: INSURANCE_WIDGET_URL[APP_ENV],
  DATADOG,
  SPLIT_IO_KEY: SPLIT_IO_KEY[APP_ENV],
  ONE_LINKS_BASE_URL: ONE_LINKS_URL,
  ONE_LINKS,
  ONE_LINKS_GENERIC,
  ONE_LINKS_ONBOARDING,
  ONE_LINK_SEARCH,
  MONEYLION_WEB_CDN,
  EDIT_PROFILE_ONE_LINK,
  MONEYLION_DOWNLOAD_APP_ROARMONEY,
  MONEYLION_SOCIAL_LINKS,
  MONEYLION_HELP_LINK,
  COMMUNICATION_PREFERENCES_URL,
  MONEYLION_REFERRAL_URL: MONEYLION_REFERRAL_URL[APP_ENV],
  MINIMUM_FETCH_INTERVAL_MILLIS: MINIMUM_FETCH_INTERVAL[APP_ENV],
  FIREBASE_CONFIG: FIREBASE_CONFIG[APP_ENV],
  FIREBASE_DEFAULT_CONFFIG: FIREBASE_DEFAULT_CONFFIG[APP_ENV],
  SPLIT_IO_KEY_ONBOARDING: SPLIT_IO_KEY_ONBOARDING[APP_ENV],
  PRIVACY_POLICY_URL: PRIVACY_POLICY_URL[APP_ENV],
  COMMUNITY_GUIDELINES_URL: COMMUNITY_GUIDELINES_URL[APP_ENV],
  COLUMN_TAX_CDN: COLUMN_TAX_CDN[APP_ENV],
  ONE_LINKS_BANNER,
  DOWNLOAD_APP_ONE_LINKS,
  FINGERPRINT_KEY,
  CONTENTFUL_SPACE_ID: CONTENTFUL_SPACE_ID[APP_ENV],
  CONTENTFUL_ACCESS_TOKEN: CONTENTFUL_ACCESS_TOKEN[APP_ENV],
  CACHE_CTF_DOWNLOAD_APP_ASSETS,
  NEXT_BUILD_ID,
  CNF_LOANS_CALCULATOR: CNF_LOANS_CALCULATOR[APP_ENV],
  CNF_SAVINGS_CALCULATOR: CNF_SAVINGS_CALCULATOR[APP_ENV],
}
export default ENV
