export interface IRecentCashAdvanceData {
  transactions: ITransaction[]
}

export interface IRepaymentMethod {
  fundId: number
  lastFourDigit: string
  logo: string
  methodName: string
  type: string
}

export interface ITransaction {
  id: string
  createdAt: string
  amount: number
  tipAmount: number
  feeAmount: number
  successful: boolean
  expectedDisbursementDate: string
  disbursementType: string
  collectionAt: string
  unpaidAmount: number
  unpaidPrincipal: number
  accountLastFour: string
  applicationSource: string
  applicationStatus: string
  type: string
  expectedProcessedDate: string
  paymentStatus: string
}

export interface ICreateAppAndLinkEsignData {
  id: string
  esignDocuments: TCashAdvanceAgreements[]
}

export interface TCashAdvanceAgreements {
  docId: string
  firstName: string
  isEsigned: boolean
  lastName: string
  title: string
}

export interface IRepaymentFundOptionsData {
  funds: IRepaymentFundOptionFund[]
}

export interface IRepaymentFundOptionFund {
  available?: boolean
  fundId: number
  methodName: string
  lastFourDigit: string
  type: string
  expiryDate: string
  logo: string
  hasProductOption: boolean
  bankName: string
}

export enum RepaymentMethodType {
  ROAR_MONEY = 'DEMAND_DEPOSIT_ACCOUNT',
  DEBIT_CARD = 'DEBIT_CARD',
}

export interface IDisbursementAmountsData {
  disbursementAmounts: IDisbursementAmount[]
}

export interface IDisbursementAmount {
  amount: number
}

export interface IGetTipRequest {
  cashAdvanceAmount: number
  tipSource: 'voluntary'
}

export interface V2Tips {
  tip: number
  display: string
}

export interface IV2TipsData {
  tipDefaultIndex: number
  tipSchemaKey: string
  tips: V2Tips[]
  isFallback: boolean
}

export interface IGetDisbursementMethodRequest {
  amount: number
  applicationSource: string
  applicationId: string
}

export interface IDisbursementMethodData {
  disbursementMethods: IDisbursementMethod[]
}

export interface IDisbursementMethod {
  available: boolean
  bankLogo: string
  bankName: string
  duration: number
  fee: IDisbursementMethodFee
  fundId: number
  isCollectionAtExtended: boolean
  isDefault: boolean
  lastFourDigit: string
  methodName: string
  repaymentDate: string
  tag: TDisbursementMethodTag
  type: string
}

export type TDisbursementMethodTag = 'STANDARD' | 'TURBO'

interface IDisbursementMethodFee {
  discountEndDate: string
  discountMessage: string
  discountTurboName: string
  feeSchemaKey: string
  finalAmount: number
  originalFee: number
}

export interface IDisbursementDetail {
  amount?: number
  method?: IDisbursementMethod
  tip?: number
}

export interface IPostApplyInstacashDisbursementRequest {
  applicationId: string
  amount: number
  applicationSource: string
  disbursementType: string
  fundId: number
  tip: {
    amount: number
    source: string
    schemaKey: string
    indexSelected: number
  }
  fee: {
    finalAmount: number
    originalFee?: number
    discountTurboName?: string
    discountMessage?: string
    discountEndDate?: string
    feeSchemaKey?: string
  }
  includeIP?: boolean
}

export interface IApplyInstacashDisbursementData {
  applicationAmount: number
  applicationId: string
  disbursementType: string
  documents: IInstacashDisbursementDocument[]
  expectedPayOffDate: string
  status: string
  transactionIdExtended: string
}

export interface IInstacashDisbursementDocument {
  id: string
  isEsigned: boolean
  pdfUrl: string
  title: string
}

export interface OnboardingQualification {
  isQualified: boolean
  qualificationRulesBreakdown: {
    instacashAddOnSubscribed: boolean
    activeCBPlusMembership: boolean
    activeWowMembership: boolean
    deactivatedWowMembership: boolean
    passedLoanUnderwriting: boolean
    hasActiveDDInRM: boolean
    experiment: {
      icTrial: boolean
      switchedDDToRM: boolean
      isBVLessIC: boolean
      otherExperiments: boolean
    }
  }
}

export interface OnboardingQualificationResponse {
  data: OnboardingQualification
}

export interface IInstacashTncEsignDocuments {
  esignDocuments: IInstacashTncEsignDocument[]
}

export enum InstacashEsignDocumentStatus {
  PENDING = 'PENDING',
  SIGNED = 'SIGNED',
}
export interface IInstacashTncEsignDocument {
  documentId: string
  documentName: string
  status: InstacashEsignDocumentStatus
  signedAt: string
}

export type IEligibilityBankStatus = 'COMPLETE' | 'UNLINKED' | 'REMOVED'

/** To Revisit:
 * 1. Only institutionProviderId can be null.
 * 2. Others would always return its type
 */
export interface IInstacashEligibilityBVAccount {
  accountLastFour: string | null
  accountNumber: string | null
  bankName: string | null
  bankStatus: IEligibilityBankStatus
  institutionProviderId: number | null
  isExternalAccount: boolean
  logo: string | null
  userId: string | null
}

export interface IInstacashTargetBVOptions {
  bvAccounts: {
    accountLastFour: string
    bankName: string
    bankStatus: IEligibilityBankStatus
    institutionId: number
    institutionProviderId: number | null
    isWalletLinked: boolean
    logo: string
  }[]
}

export interface IInstacashTimeZone {
  userStateAddress: string | null
  userTimeZone: string | null
}

export enum ICDateFormat {
  ABBR_MONTH_DAY_YEAR = 'MMM D, YYYY',
  ABBR_MONTH_DAY = 'MMM D',
  WEEKDAY_ABBR_MONTH_DAY_YEAR = 'ddd, MMM D, YYYY',
  YEAR_MONTH_DAY_WITH_DASH = 'YYYY-MM-DD',
}

export enum ICOutstandingCycleStatus {
  UNPAID = 'UNPAID',
  OPEN = 'OPEN',
}

interface PayableItem {
  id: string | null
  amount: number
  unpaidAmount: number
}

export interface ApplicationItem {
  id: string
  disbursementStatus: 'NONE' | 'PENDING' | 'SUCCESS' | 'FAILED'
  disbursementType:
    | 'EXTERNAL_DEBIT_CARD'
    | 'ACH'
    | 'ROAR_MONEY'
    | 'DELAYED_ROAR_MONEY'
    | 'RTP'
    | 'ADD_NEW_DEBIT_CARD'
    | ''

  cashAdvance: PayableItem | null
  tip: PayableItem | null
  fee: PayableItem | null
  amount: number
  unpaidAmount: number
  payableAmount: number
  createdAt: string
}

export interface CashAdvanceCycleItem {
  collectionDate: string
  applications: ApplicationItem[]
  status: ICOutstandingCycleStatus
  totalAmount: number
  totalUnpaidAmount: number
  payableAmount: number
}

export interface IOutstandingCashAdvance {
  cashAdvanceCycles: CashAdvanceCycleItem[]
  totalAmount: number
  totalUnpaidAmount: number
  totalPendingPaidAmount: number
  payableAmount: number
}

export interface IInstacashDemandDepositRepayment {
  paidAmount: number
  paymentStatus: string
}

export interface IInstacashDebitCardRepayment {
  paidAmount: number
  paymentStatus: string
}

export interface ExperimentTierEligibilityExplanation {
  tier: 'EXPERIMENT_TIER' | ''
  baseAmount: number | null
  unlockedAmount: number | null
  totalUnlocked: number | null
  progressionInfo: ProgressionInfo[] | null
}

export interface ProgressionInfo {
  amount: number
  level: number
  repaymentDate: string | null
  repaymentDeadline: string | null
  status: 'LOCKED' | 'READY' | 'IN_PROGRESS' | 'LATE' | 'UNLOCKED'
}

export interface IInstacashUpdateTargetBVAccountReq {
  bank: {
    accountNumber: string | null
    bankName: string
  }
  identifier: string // account last four
  source: 'IC_SELF_SERVICE'
}
