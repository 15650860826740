import { create } from 'zustand'
import { createBlurUp } from '@mux/blurup'
import { contentGenApi } from '@root/api'
import { IContentCardData } from '@root/types/ContentGen'
import { DDLogger } from '@root/utils'
import { DiscoverHelper } from '../helpers'

type DialogOptions = {
  title: string
  subtitle?: string
  titleSize?: number
  textAlign?: 'text-left' | 'text-center' | 'text-right'
  titleAlign?: 'text-left' | 'text-center' | 'text-right'
  buttonLabel?: string
  declineButtonLabel?: string
  confirm?: () => Promise<void>
  decline?: () => Promise<void>
}

interface TodayContentInitialState {
  showErrorDialog: boolean
  showWarningDialog: boolean
  warningDialogOptions?: DialogOptions
  errorDialogOptions?: DialogOptions
  todayContent: IContentCardData[]
  loading: boolean
  error: string | null
  activePlaybackId?: string
  isMuted: boolean
  totalCardInteraction: number
  connectionType: string
  blurVideos: Record<string, { blurDataURL: string; aspectRatio: number }>
  contentIds: string[]
}

interface TodayContentState extends TodayContentInitialState {
  getTodayContent: () => Promise<void>
  setActivePlaybackId: (playbackId: string) => void
  setIsMuted: VoidFunction
  setShowErrorDialog: (value: boolean, options?: DialogOptions) => void
  setShowWarningDialog: (value: boolean, options?: DialogOptions) => void
  updateTotalCartIteration: () => void
  reset: () => void
}

const defaultState: TodayContentInitialState = {
  todayContent: [],
  contentIds: [],
  loading: true,
  isMuted: true,
  showErrorDialog: false,
  error: null,
  blurVideos: {},
  connectionType: 'unknown',
  showWarningDialog: false,
  totalCardInteraction: 0,
}

const DEFAULT_ASPECT_RATIO = 9 / 16
const useDiscoverStore = create<TodayContentState>((set) => ({
  ...defaultState,
  setShowErrorDialog: (value, options = undefined) => {
    set({ errorDialogOptions: options, showErrorDialog: value })
  },
  setShowWarningDialog: (value, options = undefined) => {
    set({ warningDialogOptions: options, showWarningDialog: value })
  },
  getTodayContent: async () => {
    set({ loading: true, error: null })
    try {
      set({
        connectionType:
          navigator?.connection?.effectiveType ??
          (await DiscoverHelper.measureNetworkEffectiveType()),
      })

      const { data } = await contentGenApi.fetchTodayTabContent({
        layoutGroupCategory: 'DISCOVER_WEB',
      })

      const playbackIds: string[] = []
      const contentIds: string[] = []
      const todayContent = (data.layoutsAndContents ?? [])
        .flatMap((layout) => layout.contents ?? [])
        .filter((content) => {
          if (content.type === 'VerticalCard') {
            contentIds.push(content.id)
            const playbackId = content.properties?.videoProperties?.playbackId
            if (playbackId) {
              playbackIds.push(playbackId)
            }
            return true
          }
          return false
        })

      const blurVideos: TodayContentInitialState['blurVideos'] = {}

      await Promise.allSettled(
        playbackIds.map(async (playbackId) => {
          try {
            const { blurDataURL = '', aspectRatio = DEFAULT_ASPECT_RATIO } =
              await createBlurUp(playbackId, { blur: 20 })
            blurVideos[playbackId] = { blurDataURL, aspectRatio }
          } catch (error) {
            DDLogger.error(
              `Failed to create blur up for playback ID ${playbackId}:`,
              error
            )
            blurVideos[playbackId] = {
              blurDataURL: '',
              aspectRatio: DEFAULT_ASPECT_RATIO,
            }
          }
        })
      )

      set({ todayContent, loading: false, blurVideos, contentIds })
    } catch (error) {
      let errorMessage = 'Something went wrong'
      if (error instanceof Error) {
        errorMessage = error.message
      }

      set({ error: errorMessage, loading: false })
    }
  },
  setActivePlaybackId: (activePlaybackId) => {
    set({ activePlaybackId })
  },
  setIsMuted: () => {
    set(({ isMuted }) => ({
      isMuted: !isMuted,
    }))
  },
  updateTotalCartIteration: () => {
    set(({ totalCardInteraction }) => ({
      totalCardInteraction: ++totalCardInteraction,
    }))
  },
  reset: () => {
    set(defaultState)
  },
}))

export default useDiscoverStore
