import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import { cashAdvanceApi, subscriptionApi } from '@root/api'
import {
  IDisbursementDetail,
  IDisbursementMethod,
  IInstacashEligibilityBVAccount,
  IInstacashTncEsignDocument,
  IInstacashTncEsignDocuments,
  IRepaymentFundOptionFund,
  TCashAdvanceAgreements,
  IOutstandingCashAdvance,
  IRecentCashAdvanceData,
  IInstacashTimeZone,
  OnboardingQualification,
} from '@root/types/CashAdvance'
import CashAdvanceInfo from '@root/types/CashAdvanceInfo'
import { InstacashFlowType } from '@root/types/Subscription'
import { CAEligibilityExplanationResponseData } from '@root/api/cashAdvanceApi'
import { useBackdoorStore } from './backdoorStore'

export interface IInstacash {
  eligible: boolean
  payableAmount: number
  expectedPaymentAmount: number
  eligibleAvailableAmount: number
  upcomingHolidayAlert?: boolean
  eligibilityBreakdown?: {
    IS_DIRECT_DEPOSIT_CURRENTLY_LINKED: boolean
    IS_CASH_ADVANCE_ON_TIME_IF_ANY?: boolean // TODO: this and below props should be required
    IS_LOANS_ON_TIME_IF_ANY?: boolean
  }
  eligibleAmount?: {
    available: number
  }
}

export interface IRepaymentMethodDetail {
  loading: boolean
  funds?: IRepaymentFundOptionFund[]
}

interface IApplicationIdAndEsign {
  applicationId?: string
  esign?: TCashAdvanceAgreements[]
}

export interface IEligibilityBVAccountDetail {
  loading: boolean
  data?: IInstacashEligibilityBVAccount
}

export interface IOutstandingCashAdvanceDetail {
  loading: boolean
  data?: IOutstandingCashAdvance
}

export interface IRecentCashAdvanceDetail {
  loading: boolean
  data?: IRecentCashAdvanceData
}

export interface IUserTimeZoneDetail {
  loading: boolean
  data?: IInstacashTimeZone
}

export interface ICashAdvanceInfoDetail {
  loading: boolean
  data?: CashAdvanceInfo
}

export interface IOnboardingQualification {
  loading: boolean
  data?: OnboardingQualification
}

export interface IEligibilityExplanationInfo {
  loading: boolean
  data?: CAEligibilityExplanationResponseData
}

interface InstacashInitialState {
  instacash?: IInstacash | null
  applicationId?: string
  instacashEsign?: TCashAdvanceAgreements[]
  disbursementDetail: IDisbursementDetail
  disbursementMethods?: IDisbursementMethod[]
  underwritingTransactionId?: string
  repaymentMethodDetail: IRepaymentMethodDetail
  isInstacashRoarMoneyUser?: boolean
  tncEsign?: IInstacashTncEsignDocument[]
  targetBVAccount: IEligibilityBVAccountDetail
  isInstacashQualified: boolean
  isInstacashEligible: boolean
  outstandingCashAdvance: IOutstandingCashAdvanceDetail
  recentCashAdvance: IRecentCashAdvanceDetail
  userTimeZone: IUserTimeZoneDetail
  isInstacashQualificationChecking: boolean
  isInstacashEligibilityPrereqChecking: boolean
  cashAdvanceInfoDetail: ICashAdvanceInfoDetail
  onboardingQualification: IOnboardingQualification
  eligibilityExplanationInfo?: IEligibilityExplanationInfo
}

export interface InstacashState extends InstacashInitialState {
  getInstacashDetails: () => Promise<CashAdvanceInfo | undefined>
  getInstacashApplicationIdAndEsign: (
    refetch?: boolean,
    signal?: AbortSignal
  ) => Promise<IApplicationIdAndEsign>
  setDisbursementDetail: (disbursementDetail: IDisbursementDetail) => void
  setDisbursementMethods: (disbursementMethods: IDisbursementMethod[]) => void
  checkInstacashEligibility: (
    flow: InstacashFlowType,
    isIntent: boolean
  ) => Promise<string>
  setRepaymentMethodDetail: (
    repaymentMethodDetail: IRepaymentMethodDetail
  ) => void
  setIsInstacashRoarMoneyUser: (isInstacashRoarMoneyUser: boolean) => void
  getInstacashTncEsignDocuments: () => Promise<
    IInstacashTncEsignDocuments | undefined
  >
  setEligibilityExplanationInfo: (
    eligibilityExplanationInfo: IEligibilityExplanationInfo
  ) => void
  postInstacashTncEsignDocuments: () => Promise<
    IInstacashTncEsignDocuments | undefined
  >
  putInstacashTncEsignDocuments: ({
    tncEsign,
  }: {
    tncEsign: IInstacashTncEsignDocument[]
  }) => Promise<IInstacashTncEsignDocuments | undefined>

  setEligibilityBVAccount: (
    targetBVAccount: IEligibilityBVAccountDetail
  ) => void
  setIsInstacashQualified: (isInstacashQualified: boolean) => void
  setIsInstacashEligible: (isInstacashEligible: boolean) => void
  setOutstandingCashAdvance: (
    outstandingCashAdvance: IOutstandingCashAdvanceDetail
  ) => void
  setRecentCashAdvance: (recentCashAdvance: IRecentCashAdvanceDetail) => void
  setUserTimeZone: (userTimeZone: IUserTimeZoneDetail) => void
  setIsInstacashQualificationChecking: (
    isInstacashQualificationChecking: boolean
  ) => void
  setIsInstacashEligibilityPrereqChecking: (
    isInstacashEligibilityPrereqChecking: boolean
  ) => void
  setCashAdvanceInfoDetail: (
    cashAdvanceInfoDetail: ICashAdvanceInfoDetail
  ) => void
  resetInstacashApplicationIdAndEsign: () => void
  resetDisbursementDetail: () => void
  setOnboardingQualification: (
    onboardingQualification: IOnboardingQualification
  ) => void
  reset: () => void
}

const initialState: InstacashInitialState = {
  instacash: undefined,
  applicationId: undefined,
  instacashEsign: undefined,
  disbursementDetail: {
    amount: undefined,
    method: undefined,
    tip: undefined,
  },
  disbursementMethods: undefined,
  underwritingTransactionId: undefined,
  repaymentMethodDetail: {
    loading: true,
    funds: undefined,
  },
  isInstacashRoarMoneyUser: undefined,
  isInstacashQualified: false,
  isInstacashEligible: false,
  outstandingCashAdvance: {
    loading: true,
    data: undefined,
  },
  recentCashAdvance: {
    loading: true,
    data: undefined,
  },
  userTimeZone: {
    loading: true,
    data: undefined,
  },
  isInstacashQualificationChecking: true,
  isInstacashEligibilityPrereqChecking: true,
  cashAdvanceInfoDetail: {
    loading: true,
    data: undefined,
  },
  targetBVAccount: {
    loading: true,
    data: undefined,
  },
  onboardingQualification: {
    loading: true,
    data: undefined,
  },
  eligibilityExplanationInfo: {
    loading: true,
    data: undefined,
  },
}

const useInstacashStore = create<InstacashState>()(
  persist(
    (set, getState) => ({
      ...initialState,
      getInstacashDetails: async () => {
        const resp = await cashAdvanceApi.getCashAdvanceInfo()

        if (resp.data) {
          set({
            instacash: {
              eligible: resp?.data.eligible || false,
              expectedPaymentAmount: resp?.data.expectedPaymentAmount || 0,
              payableAmount: resp?.data.payableAmount || 0,
              eligibleAvailableAmount: resp?.data.eligibleAmount.available || 0,
              eligibilityBreakdown: resp?.data.eligibilityBreakdown,
              eligibleAmount: resp?.data.eligibleAmount || 0,
              upcomingHolidayAlert: resp?.data.upcomingHolidayAlert,
            },
          })
        }

        return resp.data
      },
      getInstacashApplicationIdAndEsign: async (
        refetch?: boolean,
        signal?: AbortSignal
      ) => {
        const state = getState()

        if (!state.applicationId || !state.instacashEsign || refetch) {
          const { data } = await cashAdvanceApi.postCreateAppAndLinkEsign(
            signal
          )

          set({
            applicationId: data.id,
            instacashEsign: data.esignDocuments,
          })

          return {
            application: data.id,
            instacashEsign: data.esignDocuments,
          } as IApplicationIdAndEsign
        }
        return {
          application: state.applicationId,
          instacashEsign: state.instacashEsign,
        } as IApplicationIdAndEsign
      },
      setDisbursementDetail: (disbursementDetails) => {
        const state = getState()

        set({
          disbursementDetail: {
            ...state.disbursementDetail,
            ...disbursementDetails,
          },
        })
      },
      setDisbursementMethods: (disbursementMethods) => {
        set({ disbursementMethods })
      },
      checkInstacashEligibility: async (
        flow: InstacashFlowType,
        isIntent: boolean
      ) => {
        const {
          isInstacashBvLinkingBackdoorEnabled,
          instacashBvLinkingTestValuesType,
          instacashBvLinkingTestValues,
        } = useBackdoorStore.getState()

        const payload = {
          testValues: isInstacashBvLinkingBackdoorEnabled
            ? instacashBvLinkingTestValues[instacashBvLinkingTestValuesType]
            : undefined,
          flow,
        }

        let res

        if (isIntent) {
          res = await subscriptionApi.postInstacashIntentEligibleBv(payload)
        } else {
          res = await subscriptionApi.postInstacashEligibleBv(payload)
        }

        const { status, underwritingTransactionId } = res.data

        set({
          underwritingTransactionId: underwritingTransactionId,
        })

        return status
      },
      setRepaymentMethodDetail: (repaymentMethodDetail) => {
        set({ repaymentMethodDetail })
      },
      setIsInstacashRoarMoneyUser: (isInstacashRoarMoneyUser) => {
        set({ isInstacashRoarMoneyUser })
      },
      getInstacashTncEsignDocuments: async () => {
        const response = await cashAdvanceApi.getInstacashTncEsignDocuments()
        set({ tncEsign: response.data.esignDocuments })
        return response.data
      },
      setEligibilityExplanationInfo: async (
        eligibilityExplanationInfo: IEligibilityExplanationInfo
      ) => {
        set({ eligibilityExplanationInfo })
      },
      postInstacashTncEsignDocuments: async () => {
        const response = await cashAdvanceApi.postInstacashTncEsignDocuments()
        set({ tncEsign: response.data.esignDocuments })
        return response.data
      },
      putInstacashTncEsignDocuments: async ({ tncEsign }) => {
        const response = await cashAdvanceApi.putInstacashTncEsignDocuments({
          esignDocuments: tncEsign,
        })
        set({ tncEsign: response.data.esignDocuments })
        return response.data
      },

      setEligibilityBVAccount: (targetBVAccount) => {
        set({ targetBVAccount })
      },
      setIsInstacashQualified: (isInstacashQualified) => {
        set({ isInstacashQualified })
      },
      setIsInstacashEligible: (isInstacashEligible) => {
        set({ isInstacashEligible })
      },
      setOutstandingCashAdvance: (
        outstandingCashAdvance: IOutstandingCashAdvanceDetail
      ) => {
        set({ outstandingCashAdvance })
      },
      setRecentCashAdvance: (recentCashAdvance: IRecentCashAdvanceDetail) => {
        set({ recentCashAdvance })
      },
      setUserTimeZone: (userTimeZone: IUserTimeZoneDetail) => {
        set({ userTimeZone })
      },
      setIsInstacashQualificationChecking: (
        isInstacashQualificationChecking
      ) => {
        set({ isInstacashQualificationChecking })
      },
      setIsInstacashEligibilityPrereqChecking: (
        isInstacashEligibilityPrereqChecking
      ) => {
        set({ isInstacashEligibilityPrereqChecking })
      },
      setCashAdvanceInfoDetail: (
        cashAdvanceInfoDetail: ICashAdvanceInfoDetail
      ) => {
        set({ cashAdvanceInfoDetail })
      },
      resetInstacashApplicationIdAndEsign: async () => {
        set({
          applicationId: undefined,
          instacashEsign: undefined,
        })
      },
      resetDisbursementDetail: () => {
        set({
          disbursementDetail: {
            amount: undefined,
            method: undefined,
            tip: undefined,
          },
        })
      },
      setOnboardingQualification: (onboardingQualification) => {
        set({ onboardingQualification })
      },
      reset: () => {
        set(initialState)
      },
    }),
    {
      partialize: (state) => ({
        applicationId: state.applicationId,
        instacashEsign: state.instacashEsign,
        disbursementDetail: state.disbursementDetail,
        underwritingTransactionId: state.underwritingTransactionId,
        repaymentMethodDetail: state.repaymentMethodDetail,
      }),
      name: 'instacashStore',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
)

export { useInstacashStore }
