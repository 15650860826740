import { ENV } from '@root/config'
import { FeatureFlagConfigLabels } from '@root/types/Featureflag'

export const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: ENV.SPLIT_IO_KEY_ONBOARDING,
    key: 'anonymous',
  },
}

export const SPLIT_FEATURE_FLAG = {
  ONBOARDING: {
    accountsRevampExperiment: 'ob_web_accounts_tab_revamp_experiment',
    activationListExperiment: 'ob_activation_list_experiment',
    activationWidgetExperiment: 'ob_web_activation_widget_experiment',
    autoInsuranceEntryPoints: 'ob_insurance_entry_points',
    carouselExperiment: 'ob_web_product_intent_ic_rm',
    cbRedirectToMobile: 'ob_web_cb_loan_redirect_to_mobile',
    icDenialEnabled: 'ob_web_ic_denial_experiment',
    productRedirection: 'ob_web_product_redirection',
    shouldIpAddressIncluded: 'ob_web_ic_apply_ip_enabled',
    spendInsightsTransactionEnabled:
      'ob_web_spend_insights_transaction_rollout',
    WOW_INTRO_PAGE_EXPERIMENT: 'ob_web_wow_onboarding_landing_experiment',
    wowOnboarding: 'ob_web_wow_onboarding',
    IDOLOGY_KBA_ROLLOUT: 'ob_web_idology_kba_rollout',
    BUDGET_EXPERIMENT: 'ob_web_budget_experiment',
    LINKED_ACCOUNT: 'ob_web_linked_account',
    WEB_TO_APP_MODAL_POST_IDV: 'ob_web_to_app_modal_post_idv',
    SPEND_INSIGHTS_EDIT_TRANSACTION: 'ob_web_spend_insights_edit',
    D5_MISSION_REWARDS_EXPERIMENT: 'ob_d5_mission_rewards_experiment',
    BUDGET_NATIVE_UI_CARD: 'ob_web_budget_native_ui_card',
    DDA_ADMIN_FEE_REMOVAL: 'dda_admin_fee_removal',
    ROUNDUPS_MANAGED_INVEST_WIDGETS: 'ob_web_roundups_inv_widget',
    ROUND_UP_SIGNUP: 'ob_web_roundup_signup',
  },
  MPL: {
    /**
     * ON - the LiAT widget new design
     * OFF - the LiAT widget old design
     */
    LIAT_WIDGET_V3: 'mpl_web_liat_widget_v3_enabled',
    LAC_WIDGET_V2: 'mpl_web_lac_widget_v2_enabled',
    LAYOUT_V2: 'mpl_web_products_and_offers_layout_v2_enabled',
    CASHBACK_LIAT_WIDGET: 'mpl_web_liat_widget_cashback_banner_enabled',
    LIAT_HOME_PAGE: 'mpl_web_liat_home_experiment',
    LIAT_HOME_PAGE_V2: 'mpl_home_page_liat_v2',
    RIGHT_SECTION_BLOGS: 'mpl_web_right_section_blog',
    ACCOUNTS_LIAT_ENTRYPOINT: 'mpl_account_page_entry_point',
    SHOW_OFFER_COUNTDOWN: 'mpl_loan_offers_expiry_counter_rollout',
    SHOW_MONEYTOOLS: 'mpl_webdash_moneytools'
  },
  WEB: {
    AKOYA_SUPPORTED: 'bv_akoya_provider_enabled',
    MAINTENANCE: 'web_maintenance_enabled',
    WEB_COLUMN_TAX: 'web_column_tax_enabled',
    WEB_MONEY_INSIGHTS: 'web_money_insights_enabled',
    SHOW_AGREEMENT_DIALOG: 'web_agreement_dialog_enabled',
  },
  CA: {
    icWebDashboard: 'ca_web_feature_parity_master',
    IC_DISBURSEMENT_MAINTENANCE_BANNER: 'ca_disbursement_maintenance_banner',
    IC_DASHBOARD_MAINTENANCE_BANNER: 'ca_dashboard_maintenance_banner',
    IC_MANAGE_FUNDS_FLOW: 'ca_web_manage_funds_flow_master',
    IC_REPAYMENT: 'ca_web_manual_repayment_flow_master',
    IC_ELIGIBILITY_EXPLANATION: 'ca_web_eligibility_explanation_master',
    IC_MANAGE_ELIGIBILITY_ACCOUNT: 'ca_web_manage_eligibility_account_master',
  },
  SRCH: {
    SEARCH_ROLLOUT: 'universal_search_rollout',
    ML_INSIGHTS_WIDGET: 'srch_ml_insights_rollout',
    CATEGORIES_CONFIG: 'srch_categories_config',
    MODULARIZED_FLOW_ROLLOUT: 'srch_modularized_flow_rollout',
  },
  DISCOVER: {
    DISCOVER_FEED_ENABLED: 'discover_web_dashboard_enabled',
    DISCOVER_FEED_COMMENTS_ENABLED: 'discover_web_comments_enabled',
    DISCOVER_FEED_HEART_ENABLED: 'discover_web_heart_enabled',
    DISCOVER_FEED_SHARE_ENABLED: 'discover_web_share_enabled',
    DISCOVER_FEED_BOOKMARK_ENABLED: 'discover_web_bookmark_enabled'
  },
  COMMENTS: {
    COMMENT_AUTHOR_NAME: 'comment_author_name',
    COMMENTS_CONFIG: 'comment_config',
    COMMENT_WOW_BADGE: 'comment_wow_badge',
    COMMENT_AUTHOR_AVATAR: 'comment_author_avatar',
    COMMENT_TICKER_LINKS_ENABLED: 'comment_ticker_links_enabled',
    COMMENT_BOOST_ENABLED: 'discover_web_comment_boost_enabled',
  }
} as const

export const REMOTE_CONFIG_FEATURE_FLAG = {
  WEB: {
    allowUserIdOnSegment: 'WEB_ALLOW_USERID_ON_SEGMENT',
    fingerprintPocRollout: 'WEB_FINGERPRINT_POC_ROLLOUT',
  },
  IAM: {
    loginRollout: 'IAM_LOGIN_ROLLOUT',
    signupRollout: 'IAM_SIGNUP_ROLLOUT',
    icEngineSignupRollout: 'IAM_IC_ENGINE_SIGNUP_ROLLOUT',
    productIntentSignupRollout: 'IAM_PRODUCT_INTENT_SIGNUP_ROLLOUT',
    roarmoneySignupRollout: 'IAM_ROARMONEY_SIGNUP_ROLLOUT',
    SIGNUP_HIDE_MEMBERSHIP_AGREEMENT_ENABLE: 'IAM_SIGNUP_HIDE_MEMBERSHIP_AGREEMENT_ENABLE'
  },
  MPL: {
    UUID_SIGNUP_EXPERIMENT: 'MPL_UUID_SIGNUP_EXPERIMENT',
    AUTH_SIGNIN_POPUP: 'MPL_AUTH_SIGNIN_POPUP',
    AUTH_AUTO_LOGIN: 'MPL_AUTH_AUTO_LOGIN',
    CASHBACK_VARIANT: 'MPL_CASHBACK_VARIANT',
    UUID_OFFER_LOGO: 'MPL_UUID_OFFER_LOGO',
    OFFER_COUNTDOWN: 'MPL_COUNTDOWN_LIAT_FLOW',
    IAM_SIGNUP: 'MPL_SIGNUP_FLOW',
  },
  ONBOARDING: {
    exitBarrier: 'OB_WEB_EXIT_BARRIER',
    engineLandingAppRedirect: 'OB_WEB_ENGINE_LANDING_PAGE_APP_REDIRECT',
  },
  // eslint-disable-next-line prettier/prettier
} satisfies FeatureFlagConfigLabels
