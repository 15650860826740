import { SPLIT_FEATURE_FLAG } from '@root/constants'
import { useSplitStore } from '@root/store'
import IBank, { IBankInfo, IBankSummary } from '@root/types/Bank'
import { https } from '@root/utils'

export interface IFetchTopBanksResp {
  data: {
    institutions: IBank[]
  }
}

export type BankSource = 'webpfm' | 'webinstacash' | 'webcbplus'

const fetchTopBanks = async (
  source: BankSource
): Promise<IFetchTopBanksResp> => {
  const FFTreatments = useSplitStore.getState().featureFlags
  const FFTreatment = FFTreatments[SPLIT_FEATURE_FLAG.WEB.AKOYA_SUPPORTED]
  const isAkoyaSupported = FFTreatment?.treatment === 'on'
  const resp = await (
    await https({
      errorTitle: '[fetchTopBanks]',
    })
  ).post('/bank/institution/top', {
    source,
    isAkoyaSupported: isAkoyaSupported,
  })
  return resp.data
}

const findBanks = async (
  query: string,
  source: BankSource
): Promise<IFetchTopBanksResp> => {
  // Having JS SDK allows you to use Split's client-side APIs. Without react context
  const FFTreatments = useSplitStore.getState().featureFlags
  const FFTreatment = FFTreatments[SPLIT_FEATURE_FLAG.WEB.AKOYA_SUPPORTED]
  const isAkoyaSupported = FFTreatment?.treatment === 'on'
  const body = {
    source,
    query,
    isAkoyaSupported,
    page: 0,
    size: 4,
  }

  const resp = await (
    await https({ errorTitle: '[findBanks]' })
  ).post('/bank/institution/search', body)
  return resp.data
}

interface IInitiateLinkingBody {
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  redirectUri?: string
  source: string
}

interface IInitiateLinkingResp {
  data: {
    isRelink: boolean
    plaidAttribute?: {
      linkToken: string
    }
    finicityAttribute?: {
      connectUrl: string
    }
    akoyaAttribute?: {
      externalInstitutionProviderId: string
    }
    requestId: string
  }
}

const initiateLinking = async (
  body: IInitiateLinkingBody
): Promise<IInitiateLinkingResp> => {
  const resp = await (
    await https({
      errorTitle: '[initiateLinking]',
    })
  ).post('/bank/linking/initiate', {
    ...body,
  })

  return resp.data
}

interface IPlaidLinkSuccessBody {
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  publicToken: string
  source: BankSource
}

interface IPlaidLinkSuccessResp {
  data: {
    bankId: string
  }
}

const plaidLinkSuccess = async (
  body: IPlaidLinkSuccessBody
): Promise<IPlaidLinkSuccessResp> => {
  const resp = await (
    await https({
      errorTitle: '[plaidLinkSuccess]',
    })
  ).post('/bank/linking/plaid/login-success', {
    ...body,
  })

  return resp.data
}

type PlaidMetaDataViewName =
  | 'CONNECTED'
  | 'CREDENTIAL'
  | 'ERROR'
  | 'EXIT'
  | 'LOADING'
  | 'MFA'
  | 'SELECT_ACCOUNT'
  | 'SELECT_INSTITUTION'
  | 'TRANSITION_VIEW'
  | 'IS_MATCHED_USER'
  | 'IS_MATCHED_USER_UI'
  | 'CONSENT'

export interface PlaidMetadata {
  error_code: string
  error_message: string
  error_type: string
  exit_status: string
  institution_id: string
  institution_name: string
  institution_search_query: string
  link_session_id: string
  mfa_type: string
  request_id: string
  timestamp: string
  view_name: PlaidMetaDataViewName
}

interface IPlaidLinkEventBody {
  eventName: string
  metadata: PlaidMetadata
  relink: boolean
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

const plaidLinkEvent = async (body: IPlaidLinkEventBody) => {
  const resp = await (
    await https({
      errorTitle: '[plaidLinkEvent]',
    })
  ).post('/bank/linking/plaid/link-event', {
    ...body,
  })
  return resp.data
}

interface IFetchBankAccountStatusResp {
  data: {
    banks: IBankInfo[]
  }
}

const fetchBankAccountStatus = async (
  source: BankSource,
  bankId?: string
): Promise<IFetchBankAccountStatusResp> => {
  const resp = await (
    await https({
      errorTitle: '[fetchBankAccountStatus]',
    })
  ).post('/bank/info/status', {
    source,
    bankId,
  })

  return resp.data
}

export interface IFetchBankAccountsBody {
  includePendingBank?: boolean
  includeRemovedBank?: boolean
}

export interface IFetchBankAccountsResp {
  data: {
    banks: IBankSummary[]
  }
}

const fetchBankAccounts = async (
  body: IFetchBankAccountsBody = {
    includePendingBank: true,
    includeRemovedBank: true,
  }
): Promise<IFetchBankAccountsResp> => {
  const options = { ...body, includeInsLogoUrl: true }

  const resp = await (
    await https({ errorTitle: '[fetchBankAccounts]' })
  ).post('/bank/v2/info/account', { options })
  return resp.data
}
interface IFinicityLinkSuccessBody {
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

interface IFinicityLinkSuccessResp {
  data: {
    bankId: string
  }
}

const finicityLinkSuccess = async (
  body: IFinicityLinkSuccessBody
): Promise<IFinicityLinkSuccessResp> => {
  const resp = await (
    await https({
      errorTitle: '[finicityLinkSuccess]',
    })
  ).post('/bank/linking/finicity/login-success', {
    ...body,
  })
  return resp.data
}

interface IFinicityConnectEventBody {
  type: string
  metadata: any
  relink: boolean
  institutionProviderId: number
  bvMainSession: string
  bvSubSession: string
  source: BankSource
}

const finicityConnectEvent = async (body: IFinicityConnectEventBody) => {
  const resp = await (
    await https({ errorTitle: '[finicityConnectEvent]' })
  ).post('/bank/linking/finicity/connect-event', {
    ...body,
  })

  return resp.data
}

const deleteBankAccount = async (bankId: string, source: BankSource) => {
  const resp = await (
    await https({
      errorTitle: '[deleteBankAccount]',
    })
  ).post('/bank/linking/remove', {
    bankId,
    source,
  })
  return resp.data
}

interface AkoyaLinkSuccessBody {
  bankId: number
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  source: BankSource
  akoyaAuthorizationCode: string
  akoyaRedirectUrl: string
  isParallelLinking?: boolean
}

const akoyaLinkSuccess = async (body: AkoyaLinkSuccessBody) => {
  const resp = await (
    await https({
      errorTitle: '[akoyaLinkSuccess]',
    })
  ).post('/bank/linking/akoya/login-success', {
    ...body,
  })

  return resp.data
}

type AkoyaLinkEvent =
  | 'IN_APP_OPEN'
  | 'IN_APP_CLOSE'
  | 'OAUTH_REDIRECT'
  | 'SUCCESS'
  | 'FAILURE'
  | 'CANCEL'
  | 'EXIT'

interface AkoyaLinkEventMetaData {
  eventName: AkoyaLinkEvent
  errorCode: string
  errorDescription: string
  timestamp: string
  exitStatus: string
  url: string
}

export interface AkoyaLinkEventBody {
  source: BankSource
  type: AkoyaLinkEvent
  relink: boolean
  bvMainSession: string
  bvSubSession: string
  institutionProviderId: number
  mlTimeStamp: Date
  metaData: AkoyaLinkEventMetaData
}

const akoyaLinkEvent = async (body: AkoyaLinkEventBody) => {
  const resp = await (
    await https({
      errorTitle: '[akoyaLinkEvent]',
    })
  ).post('/bank/linking/akoya/link-event', {
    ...body,
  })

  return resp.data
}

const bankApi = {
  fetchTopBanks,
  findBanks,
  initiateLinking,
  plaidLinkSuccess,
  plaidLinkEvent,
  fetchBankAccountStatus,
  fetchBankAccounts,
  finicityLinkSuccess,
  finicityConnectEvent,
  akoyaLinkSuccess,
  akoyaLinkEvent,
  deleteBankAccount,
}

export default bankApi
