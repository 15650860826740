import { Button } from '@moneylion/mlds-web'
import styled from 'styled-components'

export const ErrorContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 100vw;
  min-height: 100vh;
`

export const ReturnButton = styled(Button)`
  width: 100%;
`

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 311px;
  padding: 10px;
`
