import { MarketplaceCreditCardsPathsNames } from './marketplace'

const ONBOARDING_PATH = '/onboarding'
const MARKETPLACE_PERSONAL_LOAN_PATH = '/marketplace/personal-loan'
const MARKETPLACE_CREDIT_CARDS_PATH = '/marketplace/credit-cards'

const {
  EMAIL,
  ADDRESS,
  SSN,
  EMPLOYMENT,
  PERSONAL_INFORMATION,
  PURPOSE_CHOICE,
  OFFERS_RESULT,
  CONFIRMATION,
  CREDIT_SCORE,
} = MarketplaceCreditCardsPathsNames

const MODULE_PATHS = {
  addCard: '/add-card',
  addBank: '/add-bank',
  paymentSchedule: '/payment-schedule',
}

const PRODUCT_PATHS = {
  cbplus: '/cbplus',
  crypto: '/crypto',
  instacash: '/instacash',
  investment: '/investment',
  roarmoney: '/roarmoney',
  roundUp: '/round-up',
  wow: '/wow',
}

const LITE_ONBOARDING_PAGES_PATHS = {
  signUp: `/signup`,
  referral: `/referral`,
  welcome: `/welcome`,
}

const CORE_ONBOARDING_PAGES_PATHS = {
  dobAddress: `/dob-and-address`,
  phoneNumber: `/phone-number`,
  ssnVerification: `/ssn-and-verification`,
  personalDetails: `/personal-details`,
  kba: `/kba`,
}

const ADD_CARD_PATHS = {
  addCardAddRepaymentMethod: `${MODULE_PATHS.addCard}/add-repayment-method`,
}

const ADD_BANK_PATHS = {
  addBankBackupAuth: `${MODULE_PATHS.addBank}/backup-auth`,
}

const CBPLUS_PAGES_PATHS = {
  cbplusIntro: `${PRODUCT_PATHS.cbplus}/intro`,
  cbplusBVPreroll: `${PRODUCT_PATHS.cbplus}/bv-preroll`,
  cbplusBVLinking: `${PRODUCT_PATHS.cbplus}/bv-linking`,
  cbplusQualified: `${PRODUCT_PATHS.cbplus}/qualified`,
  cbplusNonQualified: `${PRODUCT_PATHS.cbplus}/non-qualified`,
  cbplusCreditReserve: `${PRODUCT_PATHS.cbplus}/credit-reserve`,
  cbplusAccountActivation: `${PRODUCT_PATHS.cbplus}/account-activation`,
  cbplusSuccess: `${PRODUCT_PATHS.cbplus}/success`,
}

const CRYPTO_PAGES_PATHS = {
  cryptoIntro: `${PRODUCT_PATHS.crypto}/intro`,
  cryptoPreSuccess: `${PRODUCT_PATHS.crypto}/pre-success`,
}

const INSTACASH_PAGES_PATHS = {
  instacashIntro: `${PRODUCT_PATHS.instacash}/intro`,
  instacashBVPreroll: `${PRODUCT_PATHS.instacash}/bv-preroll`,
  instacashBVLinking: `${PRODUCT_PATHS.instacash}/bv-linking`,
  instacashQualified: `${PRODUCT_PATHS.instacash}/qualified`,
  instacashTrialQualified: `${PRODUCT_PATHS.instacash}/trial-qualified`,
  instacashMoreOffers: `${PRODUCT_PATHS.instacash}/more-offers`,
  instacashDisbursementActivation: `${PRODUCT_PATHS.instacash}/disbursement-activation`,
  instacashDisbursementDestination: `${PRODUCT_PATHS.instacash}/disbursement-destination`,
  instacashDisbursementSuccess: `${PRODUCT_PATHS.instacash}/disbursement-success`,
}

const INVESTMENT_PAGES_PATHS = {
  investmentIntro: `${PRODUCT_PATHS.investment}/intro`,
  investmentStart: `${PRODUCT_PATHS.investment}/start`,
  investmentQuestion1: `${PRODUCT_PATHS.investment}/question-1`,
  investmentQuestion2: `${PRODUCT_PATHS.investment}/question-2`,
  investmentQuestion3: `${PRODUCT_PATHS.investment}/question-3`,
  investmentQuestion4: `${PRODUCT_PATHS.investment}/question-4`,
  investmentAccountActivation: `${PRODUCT_PATHS.investment}/account-activation`,
  investmentPortfolio: `${PRODUCT_PATHS.investment}/portfolio`,
}

const PAYMENT_SCHEDULE_PATHS = {
  payFrequency: `${MODULE_PATHS.paymentSchedule}/pay-frequency`,
  weeklyAndBiweekly: `${MODULE_PATHS.paymentSchedule}/weekly-and-biweekly`,
  weeklyAndBiweeklyLastPaid: `${MODULE_PATHS.paymentSchedule}/weekly-and-biweekly/last-paid`,
  twiceAMonth: `${MODULE_PATHS.paymentSchedule}/twice-a-month`,
  twiceAMonthFirstPaycheck: `${MODULE_PATHS.paymentSchedule}/twice-a-month/first-paycheck`,
  twiceAMonthFirstAndSecondPaycheck: `${MODULE_PATHS.paymentSchedule}/twice-a-month/first-and-second-paycheck`,
  monthly: `${MODULE_PATHS.paymentSchedule}/monthly`,
  monthlyDayOfWeek: `${MODULE_PATHS.paymentSchedule}/monthly/day-of-week`,
  monthlyDateOfMonth: `${MODULE_PATHS.paymentSchedule}/monthly/date-of-month`,
}

const ROARMONEY_PAGES_PATHS = {
  roarmoneyIntro: `${PRODUCT_PATHS.roarmoney}/intro`,
  roarmoneyAccountActivation: `${PRODUCT_PATHS.roarmoney}/account-activation`,
  roarmoneyActivateCard: `${PRODUCT_PATHS.roarmoney}/activate-card`,
  roarmoneyDirectDeposit: `${PRODUCT_PATHS.roarmoney}/direct-deposit`,
}

const ROUND_UP_PAGES_PATHS = {
  roundUpIntro: `${PRODUCT_PATHS.roundUp}/intro`,
  roundUpChooseCards: `${PRODUCT_PATHS.roundUp}/choose-cards`,
  roundUpChoosePrimaryBank: `${PRODUCT_PATHS.roundUp}/choose-primary-bank`,
  roundUpConfirmSettings: `${PRODUCT_PATHS.roundUp}/confirm-settings`,
  roundUpSuccess: `${PRODUCT_PATHS.roundUp}/success`,
}

const WOW_PAGES_PATHS = {
  wowIntro: `${PRODUCT_PATHS.wow}/intro`,
  wowSelectPlan: `${PRODUCT_PATHS.wow}/select-plan`,
  wowSelectPaymentMethod: `${PRODUCT_PATHS.wow}/select-payment-method`,
  wowSuccess: `${PRODUCT_PATHS.wow}/success`,
}

export const MARKETPLACE_PERSONAL_LOAN_PATHS = {
  home: `${MARKETPLACE_PERSONAL_LOAN_PATH}`,
  loanAmount: `${MARKETPLACE_PERSONAL_LOAN_PATH}/loan-amount`,
  loanPurpose: `${MARKETPLACE_PERSONAL_LOAN_PATH}/loan-purpose-choice`,
  email: `${MARKETPLACE_PERSONAL_LOAN_PATH}/email`,
  password: `${MARKETPLACE_PERSONAL_LOAN_PATH}/password`,
  creditScore: `${MARKETPLACE_PERSONAL_LOAN_PATH}/credit-score`,
  personalDetails: `${MARKETPLACE_PERSONAL_LOAN_PATH}/personal-details`,
  address: `${MARKETPLACE_PERSONAL_LOAN_PATH}/address`,
  employment: `${MARKETPLACE_PERSONAL_LOAN_PATH}/employment`,
  ssn: `${MARKETPLACE_PERSONAL_LOAN_PATH}/ssn`,
  confirmation: `${MARKETPLACE_PERSONAL_LOAN_PATH}/confirmation`,
  offersResult: `${MARKETPLACE_PERSONAL_LOAN_PATH}/offers-result`,
}

export const MARKETPLACE_CREDIT_CARDS_PATHS = {
  home: `${MARKETPLACE_CREDIT_CARDS_PATH}`,
  cardPurpose: `${MARKETPLACE_CREDIT_CARDS_PATH}/${PURPOSE_CHOICE}`,
  email: `${MARKETPLACE_CREDIT_CARDS_PATH}/${EMAIL}`,
  creditScore: `${MARKETPLACE_CREDIT_CARDS_PATH}/${CREDIT_SCORE}`,
  personalDetails: `${MARKETPLACE_CREDIT_CARDS_PATH}/${PERSONAL_INFORMATION}`,
  address: `${MARKETPLACE_CREDIT_CARDS_PATH}/${ADDRESS}`,
  employment: `${MARKETPLACE_CREDIT_CARDS_PATH}/${EMPLOYMENT}`,
  ssn: `${MARKETPLACE_CREDIT_CARDS_PATH}/${SSN}`,
  confirmation: `${MARKETPLACE_CREDIT_CARDS_PATH}/${CONFIRMATION}`,
  offersResult: `${MARKETPLACE_CREDIT_CARDS_PATH}/${OFFERS_RESULT}`,
}

export const ONBOARDING_CBPLUS_PAGES_PATHS = {
  cbplusReferral: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${LITE_ONBOARDING_PAGES_PATHS.referral}`,
  cbplusDobAddress: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${CORE_ONBOARDING_PAGES_PATHS.dobAddress}`,
  cbplusPhoneNumber: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${CORE_ONBOARDING_PAGES_PATHS.phoneNumber}`,
  cbplusSsnVerification: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${CORE_ONBOARDING_PAGES_PATHS.ssnVerification}`,
  cbplusKba: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${CORE_ONBOARDING_PAGES_PATHS.kba}`,
  cbplusIntro: `${ONBOARDING_PATH}${CBPLUS_PAGES_PATHS.cbplusIntro}`,
  cbplusBVPreroll: `${ONBOARDING_PATH}${CBPLUS_PAGES_PATHS.cbplusBVPreroll}`,
  cbplusBVLinking: `${ONBOARDING_PATH}${CBPLUS_PAGES_PATHS.cbplusBVLinking}`,
  cbplusQualified: `${ONBOARDING_PATH}${CBPLUS_PAGES_PATHS.cbplusQualified}`,
  cbplusNonQualified: `${ONBOARDING_PATH}${CBPLUS_PAGES_PATHS.cbplusNonQualified}`,
  cbplusCreditReserve: `${ONBOARDING_PATH}${CBPLUS_PAGES_PATHS.cbplusCreditReserve}`,
  cbplusInvestmentStart: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${INVESTMENT_PAGES_PATHS.investmentStart}`,
  cbplusInvestmentQuestion1: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${INVESTMENT_PAGES_PATHS.investmentQuestion1}`,
  cbplusInvestmentQuestion2: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${INVESTMENT_PAGES_PATHS.investmentQuestion2}`,
  cbplusInvestmentQuestion3: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${INVESTMENT_PAGES_PATHS.investmentQuestion3}`,
  cbplusInvestmentQuestion4: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${INVESTMENT_PAGES_PATHS.investmentQuestion4}`,
  cbplusInvestmentAccountActivation: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${INVESTMENT_PAGES_PATHS.investmentAccountActivation}`,
  cbplusInvestmentPortfolio: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${INVESTMENT_PAGES_PATHS.investmentPortfolio}`,
  cbplusPayFrequency: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.payFrequency}`,
  cbplusWeeklyAndBiweekly: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.weeklyAndBiweekly}`,
  cbplusWeeklyAndBiweeklyLastPaid: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.weeklyAndBiweeklyLastPaid}`,
  cbplusTwiceAMonth: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.twiceAMonth}`,
  cbplusTwiceAMonthFirstPaycheck: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.twiceAMonthFirstPaycheck}`,
  cbplusTwiceAMonthFirstAndSecondPaycheck: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.twiceAMonthFirstAndSecondPaycheck}`,
  cbplusMonthly: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.monthly}`,
  cbplusMonthlyDayOfWeek: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.monthlyDayOfWeek}`,
  cbplusMonthlyDateOfMonth: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${PAYMENT_SCHEDULE_PATHS.monthlyDateOfMonth}`,
  cbplusAccountActivation: `${ONBOARDING_PATH}${CBPLUS_PAGES_PATHS.cbplusAccountActivation}`,
  cbplusSuccess: `${ONBOARDING_PATH}${CBPLUS_PAGES_PATHS.cbplusSuccess}`,
}

export const ONBOARDING_CRYPTO_PAGES_PATHS = {
  cryptoReferral: `${ONBOARDING_PATH}${PRODUCT_PATHS.crypto}${LITE_ONBOARDING_PAGES_PATHS.referral}`,
  cryptoDobAddress: `${ONBOARDING_PATH}${PRODUCT_PATHS.crypto}${CORE_ONBOARDING_PAGES_PATHS.dobAddress}`,
  cryptoPhoneNumber: `${ONBOARDING_PATH}${PRODUCT_PATHS.crypto}${CORE_ONBOARDING_PAGES_PATHS.phoneNumber}`,
  cryptoSsnVerification: `${ONBOARDING_PATH}${PRODUCT_PATHS.crypto}${CORE_ONBOARDING_PAGES_PATHS.ssnVerification}`,
  cryptoKba: `${ONBOARDING_PATH}${PRODUCT_PATHS.crypto}${CORE_ONBOARDING_PAGES_PATHS.kba}`,
  cryptoIntro: `${ONBOARDING_PATH}${CRYPTO_PAGES_PATHS.cryptoIntro}`,
  cryptoRoarmoneyAccountActivation: `${ONBOARDING_PATH}${PRODUCT_PATHS.crypto}${ROARMONEY_PAGES_PATHS.roarmoneyAccountActivation}`,
  cryptoPreSuccess: `${ONBOARDING_PATH}${CRYPTO_PAGES_PATHS.cryptoPreSuccess}`,
}

export const ONBOARDING_INSTACASH_PAGES_PATHS = {
  instacashReferral: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${LITE_ONBOARDING_PAGES_PATHS.referral}`,
  instacashIntro: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashIntro}`,
  instacashDobAddress: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${CORE_ONBOARDING_PAGES_PATHS.dobAddress}`,
  instacashPhoneNumber: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${CORE_ONBOARDING_PAGES_PATHS.phoneNumber}`,
  instacashSsnVerification: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${CORE_ONBOARDING_PAGES_PATHS.ssnVerification}`,
  instacashPersonalDetails: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${CORE_ONBOARDING_PAGES_PATHS.personalDetails}`,
  instacashKba: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${CORE_ONBOARDING_PAGES_PATHS.kba}`,
  instacashBVPreroll: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashBVPreroll}`,
  instacashBVLinking: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashBVLinking}`,
  instacashQualified: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashQualified}`,
  instacashTrialQualified: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashTrialQualified}`,
  instacashMoreOffers: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashMoreOffers}`,
  instacashRoarmoneyAccountActivation: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${ROARMONEY_PAGES_PATHS.roarmoneyAccountActivation}`,
  instacashAddCardAddRepaymentMethod: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${ADD_CARD_PATHS.addCardAddRepaymentMethod}`,
  instacashDisbursementActivation: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashDisbursementActivation}`,
  instacashDisbursementDestination: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashDisbursementDestination}`,
  instacashDisbursementSuccess: `${ONBOARDING_PATH}${INSTACASH_PAGES_PATHS.instacashDisbursementSuccess}`,
}

export const ONBOARDING_INVESTMENT_PAGES_PATHS = {
  investmentReferral: `${ONBOARDING_PATH}${PRODUCT_PATHS.investment}${LITE_ONBOARDING_PAGES_PATHS.referral}`,
  investmentIntro: `${ONBOARDING_PATH}${INVESTMENT_PAGES_PATHS.investmentIntro}`,
  investmentDobAddress: `${ONBOARDING_PATH}${PRODUCT_PATHS.investment}${CORE_ONBOARDING_PAGES_PATHS.dobAddress}`,
  investmentPhoneNumber: `${ONBOARDING_PATH}${PRODUCT_PATHS.investment}${CORE_ONBOARDING_PAGES_PATHS.phoneNumber}`,
  investmentSsnVerification: `${ONBOARDING_PATH}${PRODUCT_PATHS.investment}${CORE_ONBOARDING_PAGES_PATHS.ssnVerification}`,
  investmentKba: `${ONBOARDING_PATH}${PRODUCT_PATHS.investment}${CORE_ONBOARDING_PAGES_PATHS.kba}`,
  investmentStart: `${ONBOARDING_PATH}${INVESTMENT_PAGES_PATHS.investmentStart}`,
  investmentQuestion1: `${ONBOARDING_PATH}${INVESTMENT_PAGES_PATHS.investmentQuestion1}`,
  investmentQuestion2: `${ONBOARDING_PATH}${INVESTMENT_PAGES_PATHS.investmentQuestion2}`,
  investmentQuestion3: `${ONBOARDING_PATH}${INVESTMENT_PAGES_PATHS.investmentQuestion3}`,
  investmentQuestion4: `${ONBOARDING_PATH}${INVESTMENT_PAGES_PATHS.investmentQuestion4}`,
  investmentAccountActivation: `${ONBOARDING_PATH}${INVESTMENT_PAGES_PATHS.investmentAccountActivation}`,
  investmentPortfolio: `${ONBOARDING_PATH}${INVESTMENT_PAGES_PATHS.investmentPortfolio}`,
}

export const ONBOARDING_ROARMONEY_PAGES_PATHS = {
  roarmoneyReferral: `${ONBOARDING_PATH}${PRODUCT_PATHS.roarmoney}${LITE_ONBOARDING_PAGES_PATHS.referral}`,
  roarmoneyDobAddress: `${ONBOARDING_PATH}${PRODUCT_PATHS.roarmoney}${CORE_ONBOARDING_PAGES_PATHS.dobAddress}`,
  roarmoneyPhoneNumber: `${ONBOARDING_PATH}${PRODUCT_PATHS.roarmoney}${CORE_ONBOARDING_PAGES_PATHS.phoneNumber}`,
  roarmoneySsnVerification: `${ONBOARDING_PATH}${PRODUCT_PATHS.roarmoney}${CORE_ONBOARDING_PAGES_PATHS.ssnVerification}`,
  roarmoneyKba: `${ONBOARDING_PATH}${PRODUCT_PATHS.roarmoney}${CORE_ONBOARDING_PAGES_PATHS.kba}`,
  roarmoneyIntro: `${ONBOARDING_PATH}${ROARMONEY_PAGES_PATHS.roarmoneyIntro}`,
  roarmoneyAccountActivation: `${ONBOARDING_PATH}${ROARMONEY_PAGES_PATHS.roarmoneyAccountActivation}`,
  roarmoneyActivateCard: `${ONBOARDING_PATH}${ROARMONEY_PAGES_PATHS.roarmoneyActivateCard}`,
  roarmoneyDirectDeposit: `${ONBOARDING_PATH}${ROARMONEY_PAGES_PATHS.roarmoneyDirectDeposit}`,
}

export const ONBOARDING_ROUND_UP_PAGES_PATHS = {
  roundUpDobAddress: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${CORE_ONBOARDING_PAGES_PATHS.dobAddress}`,
  roundUpPhoneNumber: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${CORE_ONBOARDING_PAGES_PATHS.phoneNumber}`,
  roundUpSsnVerification: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${CORE_ONBOARDING_PAGES_PATHS.ssnVerification}`,
  roundUpKba: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${CORE_ONBOARDING_PAGES_PATHS.kba}`,
  roundUpIntro: `${ONBOARDING_PATH}${ROUND_UP_PAGES_PATHS.roundUpIntro}`,
  roundUpChooseCards: `${ONBOARDING_PATH}${ROUND_UP_PAGES_PATHS.roundUpChooseCards}`,
  roundUpChoosePrimaryBank: `${ONBOARDING_PATH}${ROUND_UP_PAGES_PATHS.roundUpChoosePrimaryBank}`,
  roundUpAddBankBackupAuth: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${ADD_BANK_PATHS.addBankBackupAuth}`,
  roundUpConfirmSettings: `${ONBOARDING_PATH}${ROUND_UP_PAGES_PATHS.roundUpConfirmSettings}`,
  roundUpInvestmentStart: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${INVESTMENT_PAGES_PATHS.investmentStart}`,
  roundUpInvestmentQuestion1: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${INVESTMENT_PAGES_PATHS.investmentQuestion1}`,
  roundUpInvestmentQuestion2: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${INVESTMENT_PAGES_PATHS.investmentQuestion2}`,
  roundUpInvestmentQuestion3: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${INVESTMENT_PAGES_PATHS.investmentQuestion3}`,
  roundUpInvestmentQuestion4: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${INVESTMENT_PAGES_PATHS.investmentQuestion4}`,
  roundUpInvestmentAccountActivation: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${INVESTMENT_PAGES_PATHS.investmentAccountActivation}`,
  roundUpInvestmentPortfolio: `${ONBOARDING_PATH}${PRODUCT_PATHS.roundUp}${INVESTMENT_PAGES_PATHS.investmentPortfolio}`,
  roundUpSuccess: `${ONBOARDING_PATH}${ROUND_UP_PAGES_PATHS.roundUpSuccess}`,
}

export const ONBOARDING_WOW_PAGES_PATHS = {
  wowIntro: `${ONBOARDING_PATH}${WOW_PAGES_PATHS.wowIntro}`,
  wowSelectPlan: `${ONBOARDING_PATH}${WOW_PAGES_PATHS.wowSelectPlan}`,
  wowSelectPaymentMethod: `${ONBOARDING_PATH}${WOW_PAGES_PATHS.wowSelectPaymentMethod}`,
  wowSuccess: `${ONBOARDING_PATH}${WOW_PAGES_PATHS.wowSuccess}`,
}

export const ONBOARDING_ORGANIC_PAGES_PATHS = {
  referral: `${ONBOARDING_PATH}${LITE_ONBOARDING_PAGES_PATHS.referral}`,
  welcome: `${ONBOARDING_PATH}${LITE_ONBOARDING_PAGES_PATHS.welcome}`,
  dobAddress: `${ONBOARDING_PATH}${CORE_ONBOARDING_PAGES_PATHS.dobAddress}`,
  phoneNumber: `${ONBOARDING_PATH}${CORE_ONBOARDING_PAGES_PATHS.phoneNumber}`,
  ssnVerification: `${ONBOARDING_PATH}${CORE_ONBOARDING_PAGES_PATHS.ssnVerification}`,
  kba: `${ONBOARDING_PATH}${CORE_ONBOARDING_PAGES_PATHS.kba}`,
}

export const INSTACASH_DASHBOARD_PAGES_PATHS = {
  home: PRODUCT_PATHS.instacash,
  addCard: `${PRODUCT_PATHS.instacash}/add-card`,
  request: `${PRODUCT_PATHS.instacash}/request`,
  requestSuccess: `${PRODUCT_PATHS.instacash}/request-success`,
  requestDestination: `${PRODUCT_PATHS.instacash}/request-destination`,
  managePaymentMethod: `${PRODUCT_PATHS.instacash}/manage-payment-method`,
  managePaymentMethodPrescreen: `${PRODUCT_PATHS.instacash}/manage-payment-method-prescreen`,
  repayment: `${PRODUCT_PATHS.instacash}/repayment`,
  repaymentConfirmation: `${PRODUCT_PATHS.instacash}/repayment-confirmation`,
  repaymentSuccess: `${PRODUCT_PATHS.instacash}/repayment-success`,
  eligibilityExplanation: `${PRODUCT_PATHS.instacash}/eligibility-explanation`,
  manageTargetBVAccount: `${PRODUCT_PATHS.instacash}/manage-target-bv-account`,
  trialIcEligibilityExplanation: `${PRODUCT_PATHS.instacash}/trial-ic-eligibility-explanation`,
}

/**
 * @description Temporary design, will revisit our pages path structure later
 * @usage LITE_CORE_ONBOARDING_ON_VARIOUS_FLOW[flow].dobAddress
 */
export const LITE_CORE_ONBOARDING_ON_VARIOUS_FLOW = {
  organic: {
    referral: ONBOARDING_ORGANIC_PAGES_PATHS.referral,
    dobAddress: ONBOARDING_ORGANIC_PAGES_PATHS.dobAddress,
    phoneNumber: ONBOARDING_ORGANIC_PAGES_PATHS.phoneNumber,
    ssnVerification: ONBOARDING_ORGANIC_PAGES_PATHS.ssnVerification,
    kba: ONBOARDING_ORGANIC_PAGES_PATHS.kba,
  },
  instacash: {
    referral: ONBOARDING_INSTACASH_PAGES_PATHS.instacashReferral,
    dobAddress: ONBOARDING_INSTACASH_PAGES_PATHS.instacashDobAddress,
    phoneNumber: ONBOARDING_INSTACASH_PAGES_PATHS.instacashPhoneNumber,
    ssnVerification: ONBOARDING_INSTACASH_PAGES_PATHS.instacashSsnVerification,
    personalDetails: ONBOARDING_INSTACASH_PAGES_PATHS.instacashPersonalDetails,
    kba: ONBOARDING_INSTACASH_PAGES_PATHS.instacashKba,
  },
  cbplus: {
    referral: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusReferral,
    dobAddress: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusDobAddress,
    phoneNumber: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusPhoneNumber,
    ssnVerification: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusSsnVerification,
    kba: ONBOARDING_CBPLUS_PAGES_PATHS.cbplusKba,
  },
  crypto: {
    referral: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoReferral,
    dobAddress: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoDobAddress,
    phoneNumber: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoPhoneNumber,
    ssnVerification: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoSsnVerification,
    kba: ONBOARDING_CRYPTO_PAGES_PATHS.cryptoKba,
  },
  investment: {
    referral: ONBOARDING_INVESTMENT_PAGES_PATHS.investmentReferral,
    dobAddress: ONBOARDING_INVESTMENT_PAGES_PATHS.investmentDobAddress,
    phoneNumber: ONBOARDING_INVESTMENT_PAGES_PATHS.investmentPhoneNumber,
    ssnVerification:
      ONBOARDING_INVESTMENT_PAGES_PATHS.investmentSsnVerification,
    kba: ONBOARDING_INVESTMENT_PAGES_PATHS.investmentKba,
  },
  roarmoney: {
    referral: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneyReferral,
    dobAddress: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneyDobAddress,
    phoneNumber: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneyPhoneNumber,
    ssnVerification: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneySsnVerification,
    kba: ONBOARDING_ROARMONEY_PAGES_PATHS.roarmoneyKba,
  },
  roundUp: {
    dobAddress: ONBOARDING_ROUND_UP_PAGES_PATHS.roundUpDobAddress,
    phoneNumber: ONBOARDING_ROUND_UP_PAGES_PATHS.roundUpPhoneNumber,
    ssnVerification: ONBOARDING_ROUND_UP_PAGES_PATHS.roundUpSsnVerification,
    kba: ONBOARDING_ROUND_UP_PAGES_PATHS.roundUpKba,
  },
}

export const PROTECTED_PAGES_PATHS = {
  home: '/home',
  offers: '/products-offers',
  accounts: '/accounts',
  budget: '/budget',
  referrals: '/referrals',
  profile: '/profile',
  taxFiling: '/tax-filing',
  search: '/search',
  discover: '/discover',
  callback: '/auth/callback',
  instacash: {
    ...INSTACASH_DASHBOARD_PAGES_PATHS,
  },
  ...ONBOARDING_ORGANIC_PAGES_PATHS,
  ...ONBOARDING_CBPLUS_PAGES_PATHS,
  ...ONBOARDING_CRYPTO_PAGES_PATHS,
  ...ONBOARDING_INSTACASH_PAGES_PATHS,
  ...ONBOARDING_INVESTMENT_PAGES_PATHS,
  ...ONBOARDING_ROARMONEY_PAGES_PATHS,
  ...ONBOARDING_ROUND_UP_PAGES_PATHS,
  ...ONBOARDING_WOW_PAGES_PATHS,
}

export const UNPROTECTED_PAGES_PATHS = {
  root: '/',
  login: '/login',
  mfaOptions: '/login/mfa-options',
  mfaCode: '/login/mfa-code',
  forgotPassword: '/login/forgotpassword',
  forgotPasswordConfirm: '/login/forgotpassword/confirm',
  logout: '/logout',
  signUp: {
    organic: `${ONBOARDING_PATH}${LITE_ONBOARDING_PAGES_PATHS.signUp}`,
    cbplus: `${ONBOARDING_PATH}${PRODUCT_PATHS.cbplus}${LITE_ONBOARDING_PAGES_PATHS.signUp}`,
    crypto: `${ONBOARDING_PATH}${PRODUCT_PATHS.crypto}${LITE_ONBOARDING_PAGES_PATHS.signUp}`,
    instacash: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}${LITE_ONBOARDING_PAGES_PATHS.signUp}`,
    instacashEngine: `${ONBOARDING_PATH}${PRODUCT_PATHS.instacash}/engine${LITE_ONBOARDING_PAGES_PATHS.signUp}`,
    investment: `${ONBOARDING_PATH}${PRODUCT_PATHS.investment}${LITE_ONBOARDING_PAGES_PATHS.signUp}`,
    roarmoney: `${ONBOARDING_PATH}${PRODUCT_PATHS.roarmoney}${LITE_ONBOARDING_PAGES_PATHS.signUp}`,
    wow: `${ONBOARDING_PATH}${PRODUCT_PATHS.wow}${LITE_ONBOARDING_PAGES_PATHS.signUp}`,
  },
  insurance: '/insurance',
}

export const DARK_MODE_PAGES_PATHS = [
  PROTECTED_PAGES_PATHS.wowIntro,
  PROTECTED_PAGES_PATHS.wowSelectPlan,
  PROTECTED_PAGES_PATHS.wowSelectPaymentMethod,
  PROTECTED_PAGES_PATHS.wowSuccess,
]

export const MPL_ROUTES = {
  personal_loan: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.home,
    title: null,
    subtitle: null,
    tooltip: null,
    info_tips: null,
    events: {
      evenUUID: 'webmpl_offers_uuid_flow',
      loanEnter: 'loan_mpl_application_enter',
      cashbackSidebar: 'webmpl_wow_banner',
    },
  },
  loan_amount: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.loanAmount,
    title: 'I’m looking to borrow',
    subtitle:
      "Compare rates and get personalized offers. It's fast, free, and won't affect your credit score.",
    tooltip: null,
    info_tips:
      'Don’t worry: checking the amount to borrow won’t affect your credit score.',
    showPartner: true,
    events: {
      loanAmount: 'webmpl_loan_amount_submit',
    },
  },
  loan_purpose: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.loanPurpose,
    title: 'Why do you want to borrow?',
    subtitle: null,
    tooltip: null,
    info_tips: 'Your offers will be personalized to your goals.',
    showPartner: true,
    events: {
      loanPurposeChoice: 'webmpl_loan_purpose_submit',
    },
  },
  email: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.email,
    title: 'What’s your email address?',
    subtitle: null,
    tooltip: null,
    info_tips:
      'Your email helps our loan partners send you offers directly to you.',
    events: {
      email: 'acc_lite_email_submit',
      signInPopupView: 'webmpl_loan_email_validation_modal_view',
      signInPopupSubmit: 'webmpl_loan_email_validation_modal_submit',
      signUpPopupView: 'webmpl_lite_acc_modal_view',
      signUpPopupSubmit: 'webmpl_lite_acc_modal_submit',
    },
  },
  password: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.password,
    title: null,
    subtitle: null,
    tooltip: null,
    info_tips: null,
    events: {
      skipSignUp: 'webmpl_account_skip',
      createLiteAccount: 'acc_lite_create_submit',
    },
  },
  credit_score: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.creditScore,
    title: 'What is your approximate credit score?',
    subtitle: null,
    tooltip: null,
    info_tips:
      'Your credit score helps us match you to a loan that’s tailored to your financial profile.',
    events: {
      creditScore: 'webmpl_credit_score_submit',
    },
  },
  personal_details: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.personalDetails,
    title: 'Fill in your personal details',
    subtitle: null,
    tooltip: null,
    info_tips:
      'Your information helps our loan partners send you offers tailored to your needs.',
    events: {
      fullName: 'acc_lite_name_submit',
      dob: 'webmpl_birthdate_submit',
      phone: 'webmpl_phone_submit',
    },
  },
  address: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.address,
    title: 'What’s your home address?',
    subtitle: 'Make sure it’s your registered home address, and not a PO box.',
    tooltip: null,
    info_tips:
      'This will help personalize offers based on your current location.',
    events: {
      address: 'webmpl_address_submit',
    },
  },
  employment: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.employment,
    title: 'Tell us more about yourself',
    subtitle: null,
    tooltip: null,
    info_tips:
      'Our lending partners need this to provide you offers tailored to your financial profile.',
    events: {
      employment: 'webmpl_employment_submit',
      annualIncome: 'webmpl_annual_income_submit',
      education: 'webmpl_education_submit',
    },
  },
  ssn: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.ssn,
    title: 'Enter your SSN',
    subtitle:
      'We require this for anti-fraud purposes and to verify your identity. Make sure your SSN is accurate.',
    tooltip:
      'We ask for some of your personal information (Legal Name, Date of Birth,\n' +
      '      Street Address, Social Security Number etc.) so we and our partners can identify you. This\n' +
      '      ensures seamless account opening and lets MoneyLion and its partners meet USA PATRIOT Act requirements.',
    info_tips: null,
    events: {
      ssn: 'webmpl_ssn_submit',
    },
  },
  confirmation: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.confirmation,
    title: 'Please make sure everything is correct',
    subtitle: 'Here’s what you provided',
    tooltip: null,
    info_tips: null,
    events: {
      confirmation: 'webmpl_confirm_details_submit',
    },
  },
  offers_result: {
    path: MARKETPLACE_PERSONAL_LOAN_PATHS.offersResult,
    title: "We've found the following offers that match your needs",
    title_2: "Congratulations! We've matched you with the following offers",
    subtitle: null,
    tooltip: null,
    info_tips: null,
    events: {
      signUpPopup: 'webmpl_lite_account_popup',
      offersResult: 'webmpl_offers_list_view',
      offerApply: 'webmpl_offer_apply',
    },
  },
}

export const CREDIT_CARD_FLOW_PATHS = {
  credit_cards: {
    path: MARKETPLACE_CREDIT_CARDS_PATH,
    title: null,
    subtitle: null,
    tooltip: null,
    info_tips: null,
    events: {
      // verify with KY for events
      evenUUID: 'webmpl_offers_uuid_flow',
      loanEnter: 'loan_mpl_application_enter',
      cashbackSidebar: 'webmpl_wow_banner',
    },
  },
  card_purpose: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.cardPurpose,
    title: 'Why are you looking for a new credit card?',
    subtitle: null,
    tooltip: null,
    info_tips:
      'Your recommended credit card would be personalized to your goal.',
    showPartner: false,
    events: {
      loanPurposeChoice: 'webmpl_card_purpose_submit',
    },
  },
  email: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.email,
    title: 'What’s your email address?',
    subtitle: null,
    tooltip: null,
    info_tips:
      'Your email helps our loan partners send you offers directly to you.',
    events: {
      email: 'acc_lite_email_submit',
      signInPopupView: 'webmpl_loan_email_validation_modal_view',
      signInPopupSubmit: 'webmpl_loan_email_validation_modal_submit',
      signUpPopupView: 'webmpl_lite_acc_modal_view',
      signUpPopupSubmit: 'webmpl_lite_acc_modal_submit',
    },
  },
  credit_score: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.creditScore,
    title: 'What is your approximate credit score?',
    subtitle: null,
    tooltip: null,
    info_tips:
      'Your credit score helps us match you to a loan that’s tailored to your financial profile.',
    events: {
      creditScore: 'webmpl_credit_score_submit',
    },
  },
  personal_details: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.personalDetails,
    title: `Fill in your personal details`,
    subtitle: 'This information helps us confirm your identity',
    tooltip: null,
    info_tips:
      'Your information helps our loan partners send you offers tailored to your needs.',
    events: {
      fullName: 'acc_lite_name_submit',
      dob: 'webmpl_birthdate_submit',
      phone: 'webmpl_phone_submit',
    },
  },
  address: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.address,
    title: 'What’s your home address?',
    subtitle: 'Make sure it’s your registered home address, and not a PO box.',
    tooltip: null,
    info_tips:
      'This will help personalize offers based on your current location.',
    events: {
      address: 'webmpl_address_submit',
    },
  },
  employment: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.employment,
    title: 'Provide us your income',
    subtitle: null,
    tooltip: null,
    info_tips:
      'Our lending partners need this to provide you offers tailored to your financial profile.',
    events: {
      employment: 'webmpl_employment_submit',
      annualIncome: 'webmpl_annual_income_submit',
      education: 'webmpl_education_submit',
    },
  },
  ssn: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.ssn,
    title: 'Enter your SSN',
    subtitle:
      'We require this for anti-fraud purposes and to verify your identity. Make sure your SSN is accurate.',
    tooltip:
      'We ask for some of your personal information (Legal Name, Date of Birth,\n' +
      '      Street Address, Social Security Number etc.) so we and our partners can identify you. This\n' +
      '      ensures seamless account opening and lets MoneyLion and its partners meet USA PATRIOT Act requirements.',
    info_tips: null,
    events: {
      ssn: 'webmpl_ssn_submit',
    },
  },
  confirmation: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.confirmation,
    title: 'Please make sure everything is correct',
    subtitle: 'Here’s what you provided',
    tooltip: null,
    info_tips: null,
    events: {
      confirmation: 'webmpl_confirm_details_submit',
    },
  },
  offers_result: {
    path: MARKETPLACE_CREDIT_CARDS_PATHS.offersResult,
    title: "We've found the following offers that match your needs",
    title_2: "Congratulations! We've matched you with the following offers",
    subtitle: null,
    tooltip: null,
    info_tips: null,
    events: {
      signUpPopup: 'webmpl_lite_account_popup',
      offersResult: 'webmpl_offers_list_view',
      offerApply: 'webmpl_offer_apply',
    },
  },
}
